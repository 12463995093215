@import '../variables';
@import '../mixins/card';
@import '../mixins/scrollable-card-container';

.hi-listing-page {
  background-color: #f9f9f9;

  @include media-breakpoint-down(sm) {
    background-color: white;
  }

  &__rent-vs-buy {
    @include hi-top-orange();
    @include hi-border-radius-smoothen(bottom);

    padding: 1.25rem 3rem;
    background-color: white;

    &__h4 {
      font-size: 22px;
      font-weight: 500;
    }

    &__p {
      line-height: 1.63;
      font-size: 16px;
    }
  }

  &__is-occupied {
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.4) 45%, rgba(255,255,255,0.0) 100%);
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    z-index: 99;

    // Allow touches to go behind the occupied property overlay
    pointer-events: none;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    &__orange {
      color: $hoppler-orange;
      font-size: 18px;
      font-weight: 500;
    }

    p {
      color: $hi-light-gray-text-color;
      font-size: 16px;
    }
  }

  &__offline-p {
    font-size: 15px;
    line-height: 20px;
  }
}

.hi-listing-page__popup-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;

  background-color: rgba(0,0,0,0.5);
  pointer-events: all;

  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  opacity: 0;
  z-index: 0;
  transition: opacity .25s ease;

  .popup-loading & {
    visibility: visible;
    z-index: 69;
    opacity: 1;
  }
}

$carousel-base-height: 320px;
$carousel-base-height-md: 420px;
.hi-listing-page__carousel {
  $border-height: 4px;

  height: $carousel-base-height + $border-height;
  border-bottom: $border-height solid $hoppler-orange;
  @include media-breakpoint-up(md) {
    height: $carousel-base-height-md + $border-height;
  }

  // Make this flush with the viewport edges
  margin: 0 -15px;
  @include media-breakpoint-up(md) {
    margin: 0;
  }

  background-color: gray;
}

.hi-listing-page__carousel-img {
  height: $carousel-base-height;

  @include media-breakpoint-up(md) {
    height: $carousel-base-height-md;
  }

  object-fit: cover;
  display: block;
  width: 100%;
}

.hi-listing-page__carousel-container {
  position: relative;

  .hi-listing-page__carousel-building {
    display: none;
  }

  &.building {
    .hi-listing-page__carousel-property {
      display: none;
    }
    .hi-listing-page__carousel-building {
      display: block;
    }
  }
}

.hi-listings-page__carousel-toggle {
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 8px;
}

.hi-listings-page__carousel-toggle-btn {
  background-color: white;
}

.hi-listing-page__h1 {
  font-family: $hi-heading-font, serif;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.hi-listing-page__heading-row--desktop {
  display: none;
  margin-bottom: .75rem;

  .hi-type-caption {
    font-size: 14px;
  }

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.hi-listing-page__heading-row--mobile {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.hi-listing-page__h1--top-heading {
  font-size: 2rem;
}

.hi-listing-page__main-details {
  border-top: 1px solid $hi-divider-color;

  @include media-breakpoint-up(md) {
    padding: 1.5rem 1rem;

    border: 1px solid $hi-divider-color;
    border-top: none;
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
    background-color: white;
  }
}

.hi-listing-page__presale-flag {
  position: absolute;
  padding: 4px;
  bottom: 18px;
  left: 18px;
  z-index: 2;
  border-radius: 2px;
  background-color: white;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.hi-listing-page__starts-at {
  color: $hoppler-blue;
  font-size: 18px;
  display: block;
  margin-bottom: -1rem;
}

.hi-listing-page__price {
  color: $hoppler-blue;
  font-weight: 500;
  margin-top: .75rem;
  display: block;
  font-size: 1.5rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.75rem;
  }
}

.hi-listing-page__old-price {
  color: $hoppler-blue;
  font-weight: 500;
  display: block;
  padding-top: 0;
  margin-top: 0;
  font-size: 1.2rem;
  text-decoration: line-through;

  @include media-breakpoint-up(lg) {
    font-size: 1.2rem;
  }
}

.hi-video-btn {
  border: 1px solid $hoppler-orange;
  transition: 200ms;
  cursor: pointer;

  .label{
    color: $hoppler-orange;
    transition: 200ms;
  }

  &:hover { background-color: $hoppler-orange; }
  &:hover > .label{
    color: white;
  }
}

.hi-listing-page__price-suffix {
  font-size: 14px;
}

.hi-listing-page__price-per-sqm {
  color: $hoppler-blue;
}

.hi-listing-page__property-key {
  @include media-breakpoint-up(lg) {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-size: 0.8rem;  
  }

  strong {
    @include media-breakpoint-up(lg) {
      font-weight: 500;
      font-size: 1.4rem;
      padding-right: .5rem;
    }
  }
}

.hi-listing-page__key-stats {
  // width: 100%;
  display: flex;
  margin-bottom: 1.5rem;

  
  @include media-breakpoint-up(md) {
    margin-bottom: 1rem;
  }

  &.first {
    margin-bottom: 0;
  }
}

.hi-listing-page__key-stats__col {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.hi-listing-page__key-stats__stat {
  margin-top: 1rem;

  @include media-breakpoint-up(md) {
    margin-top: 0.5rem;
  }
}

.hi-listing-page__key-stats__stat-small {
  display: block;
}

.hi-listing-page__status-block {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .row.highlighted {
    background-color: #fff0e8;
    padding-bottom: 0.5rem;
    border-radius: $border-radius;
  }

  &__date-modified-alt {
    color: #999;
    font-size: 13px;
  }
}

.hi-listing-page__mobile-inquiry-block {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid $hi-divider-color;
  z-index: 32;

  display: flex;
  flex-direction: row;
  justify-content: center;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.hi-listing-page__mobile-inquiry-container {
  margin-right: 0.75rem;
  width: 100%;
}

.hi-listing-page__mobile-inquiry-h4 {
  font-size: 15px;
  font-weight: 500;
}

.hi-listing-page__right_section {
  // height: 400px;
  // border: 1px solid #c0c0c0;
  box-shadow: 0px 1px 1px 0px $hoppler-shadow-color;
  background-color: white;

  position: sticky;
  top: 76px;

  >div {
    border-top: 5px solid $hoppler-orange;
  }
}

.hi-listing-page__inquiry-block {

  &__defaultImage {
    height: 180px;
    overflow: hidden;
    width: 139px;
    border-radius: 3px;
    margin-right: 1rem;

    img {
      height: 100%;
      width: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  &__sdImage {
    height: 180px;
    overflow: hidden;
    width: 139px;
    border-radius: 3px;
    margin-right: 1rem;

    img {
      height: 100%;
      width: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  .default-text {
    font-size: 14px;
    margin-top: 1rem;
    color: $hi-light-gray-text-color;
  }
}

.sd_licenseNumber {
  font-size: 14px;
}

.hi-listing-page__mobile-sdBlock {
  margin-bottom: 1.5rem;

  .title {
    font-size: 22px;
    font-weight: 500;
    margin-top: 1rem;
  }

  &-photo {
    width: 139px;
    height: 187px;
    margin-right: 1rem;
    overflow: hidden;
    
    img {
      border-radius: 3px;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  
  &-details {
    padding-top: 0.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .sd-name, .sd-prc {
      font-size: 16px;
      line-height: 20px;
      color: black;
      margin-bottom: 0;
    }

    .text {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

.hi-listing-page__inquiry-block-h4 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.hi-listing-page__inquiry-block-p {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
}

.hi-listing-page__right-section-hr-container {
  height: 1px;
}

.hi-listing-page__right-section-hr {
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  transform: translateY(-4px);
  border-top: 1px solid $hi-divider-color;
}

.hi-listing-page__right-section-contact-numbers {
  margin-bottom: 2rem;
  font-size: 14px;
}

.hi-listing-page__share-this-property {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.hi-listing-page__share-this-property-label {
  font-size: 14px;
  line-height: 1.71;
  text-transform: uppercase;
}
.hi-listing-page__share-this-property-buttons {
  i {
    font-size: 26px;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}

.hi-listing-page__status-item {
  margin-top: 0.5rem;
}

.hi-listing-page__status-item__label {
  font-weight: bold;
}

.hi-listing-page__description {
  max-height: 64px;
  overflow: hidden;

  &.opened {
    max-height: none;
    overflow: initial;
  }
}

.hi-listing-page__description-read-more {
  font-size: .875rem;
  padding-left: 0;

  &.active {
    display: none;
  }
}

.hi-listing-page__similar {
  margin: 0 -15px;
  padding-bottom: 2rem;

  @include media-breakpoint-down(sm) {
    background-color: #f9f9f9;
  }

  @include hi-scrollable-card-container;
}

.hi-listings-page__virtual-tour {
  width: 100%;
  height: 90%;

  @include media-breakpoint-up(md) {

    min-height: 400px;
    height: 50%;
  }
}


// MORTGAGE CALCULATOR
.hi-listing-page__mortgage-calc {
  @include media-breakpoint-down(sm) {
    border-top: 1px solid $hi-divider-color;
    margin-top: 1.25rem;
  }

  @include media-breakpoint-up(md) {
    @include hi-comp-card;
  }

  &__heading {
    @include media-breakpoint-down(sm) {
      @include hi-type-caps;
      padding: 0;
      margin-top: 1.25rem;
      margin-bottom: .75rem;
    }

    @include media-breakpoint-up(md) {
      @include hi-type-title;
    }
  }

  label {
    font-weight: 500;
  }

  &__money-row {
    display: flex;
  }

  &__money-php {
    flex: 1;
    flex-wrap: nowrap;
  }

  &__money-pct {
    min-width: 96px;
    width: 96px;
    margin-left: 1rem;
    height: 100%;
  }

  &__annual-rate {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    &__dropdown, &__label {
      flex: 0 0 100%;
    }

    &__input {
      flex: 0 0 40%;
    }

    &__credits {
      margin-left: 1.5rem;
      flex: 1;
    }
  }

}

// Mortgage Calculator styles

.mortgage-flex__flex-item {
  width: 100%;
}

.chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.chart__legend {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.5rem;

  list-style: none;
  padding: 0;
}

.chart__legend-item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.chart__legend-mark {
  min-height: 12px;
  max-height: 12px;
  min-width: 12px;
  max-width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;

  &.chart__legend-mark--interest {
    background-color: $hoppler-shadow-color;
  }

  &.chart__legend-mark--principal {
    background-color: $hoppler-blue;
  }
}

.chart__legend-text {
  color: $hoppler-shadow-color;
}

.chart__canvas {
  margin-bottom: 0.5rem;
}

.chart__canvas-label {
  color: $hoppler-shadow-color;
}

.chart__canvas-value {
  font-weight: bold;
  font-size: 20px;
  color: $hoppler-blue;
}

.social-media-share-btn {
  color: $hi-text-color;
}