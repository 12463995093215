@import '../../mixins/type';

.hi-mag-category__title {
  @include hi-type-display-medium-desktop-ink;
  font-size: 21px;

  @include media-breakpoint-up(md) {
    font-size: 25px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 32px;
  }
}

.hi-mag-category__description {
  font-size: 14px;
  line-height: 1.93;

  @include media-breakpoint-up(md) {
    line-height: 1.88;
    font-size: 16px;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 2rem;
  }
}
