@import '../variables';
@import '../mixins/card';

.hi-colored-card {
  @include hi-colored-card;
}

.hi-colored-card-header {
  background-color: $hoppler-orange;
  font-size: 17px;
  color: white;
  text-align: center;

  font-weight: 500;
  text-transform: uppercase;
  padding: 8px;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}
