@import 'utils';
@import 'variables';

.hi-nav-padding {
  // padding must be equal to the height of the biggest item in hoppler-logo
  width: auto;

  padding-bottom: $navbar-height;
  
  @include media-breakpoint-up(sm) {
    @media (orientation: portrait) {
      padding-bottom: $navbar-height-sm;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: $navbar-height-lg;
  }
}


.hi-navbar {
  padding: 0;
  // -webkit-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10);
  // -moz-box-shadow:    0px 4px 16px 0px rgba(0, 0, 0, 0.10);
  // box-shadow:         0px 4px 16px 0px rgba(0, 0, 0, 0.10);

  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
}

.hi-navbar__content {
  width: 100%;
  border-bottom: 1px solid #eee;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hi-navbar, .hi-navbar__content {
  height: $navbar-height;
  display: flex;
  
  @include media-breakpoint-up(sm) {
    @media (orientation: portrait) {
      height: $navbar-height-sm;
    }
  }

  @include media-breakpoint-up(lg) {
    height: $navbar-height-lg;
  }
}

.hi-navbar__logo {
  // Force hardware accelerated rendering. This fixes a strange rendering bug:
  // https://app.asana.com/0/813243342309949/907800960834858
  transform: translate3d(0, 0, 0);
  height: 42px;
  width: auto;

  @include media-breakpoint-up(sm) {
    height: 44px;
  }

  @include media-breakpoint-up(xl) {
    height: 76px;
  }
}

.hi-navbar__nav-button,
.hi-navbar__post-property {
  font-size: .875rem;
  @include media-breakpoint-up(xl) {
    font-size: 1rem;
  }
}

.hi-navbar__nav-button {
  &__vl-icon {
    font-size: 1.5em;
  }
}

.hi-navbar__inquire,
.hi-navbar__nav-button {
  box-shadow: none;
  color: #333;
  height: 100%;

  display: flex;
  align-items: center;

  padding: 0.25rem .25rem;

  @media (min-width: 330px) {
    padding: 0.25rem 0.5rem;
  }

  @include media-breakpoint-up(md) {
    padding: 0.25rem 0.5rem;
  }

  &:hover {
    color: #333;
    text-decoration: none;
    background-color: #f6f6f6;
  }
}

.hi-navbar__cart-size-count {
  background-color: $hoppler-orange;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  text-align: center;

  @include media-breakpoint-up(md) {
    width: 1.5rem;
    height: 1.5rem;
  }

  display: inline-block;
  margin-left: .25rem;
  padding-right: 1px;
}

.hi-navbar__cart-size-count__label {
  color: white;
  font-weight: 500;
  font-size: .8rem;

  &-amp {
    display: block;
    margin-top: 2px;
  }
}

.hi-navbar__post-property {
  font-weight: bold;
  display: flex;
  align-items: center;
  color: $hoppler-orange;

  @include media-breakpoint-up(xl) {
    display: block;

    font-weight: normal;
    font-size: 1rem;
    height: 36px;
  }
  
}

.hi-navbar__nav-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  font-weight: 700;

  @include media-breakpoint-down(md) {
    text-decoration: none;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.hi-navbar__nav-icon {
  height: 24px;
  width: 24px;

  @include media-breakpoint-up(lg) {
    margin-right: 3px;
  }
}

.hi-navbar__nav-button__label {
  text-transform: uppercase;
  color: #333;
  font-size: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    text-transform: none;
  }
}

.hi-navbar__mobile-menu-collapse {
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  position: fixed;
  top: $navbar-height;
  bottom: 0px;
  right: 0px;
  left: 0px;

  @include media-breakpoint-up(sm) {
    top: $navbar-height-sm
  }

  @include media-breakpoint-up(lg) {
    top: $navbar-height-lg;
  }
}

.hi-navbar__mobile-menu-collapse__content {
  flex: 2;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  min-height: calc(100vh - 60px - 48px);
  @include media-breakpoint-up(lg) {
    min-height: calc(100vh - 72px - 48px);
  }
}

.hi-navbar__mobile-menu-collapse__controls {
  display: none;

  .collapse.show & {
    display: flex;
    flex-direction: row;

    justify-content: flex-end;
    align-items: center;
    
    height: 48px;
  }
}

