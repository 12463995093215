@import "../variables";
@import '../mixins/type';

.hi-owner-page {
  scroll-behavior: smooth;
  padding: 1rem 0;
  
  &__landing {
    border-bottom: 5px solid $hoppler-orange;
    padding-bottom: 1rem;
    margin-bottom: 3rem;
    background-image: url('https://dzjqf1alh39sw.cloudfront.net/hoppler/pages/static/kumita/kumita-banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

    @include media-breakpoint-down(xl) {
      background-size: contain;
    }

   // ipad pro
    @media only screen and (max-width: 1200px) {
      background-position-x: -5rem;
      background-size: cover;
    }

    @include media-breakpoint-down(md) {
      background-position-x: -8rem;
    }

    @include media-breakpoint-down(sm) {
      background-position-x: -48rem;
      background-size: cover;
    }
    @include media-breakpoint-down(xs) {
      background-position-x: -55rem;
    }
  }


  &__title {
    font-family: $hi-heading-font;
    font-size: 29px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.61;
    letter-spacing: normal;

    @include media-breakpoint-down(md) {
      font-size: 21px;
    }
  }

  &__header {
    color: $hoppler-orange;
    font-size: 25px;

    @include media-breakpoint-down(md) {
      font-size: 21px
    }
  }

  &__col {
    @include media-breakpoint-down(sm) {
      margin-bottom: 3rem;
    }

    &-img {
      width: 168px;
      height: auto;
    }
  }

  &__lists {
    margin-top: 5rem;
    ul {
      i {
        font-size: 10px;
        margin-top: 0.35rem;
      }
      li {
        list-style: none;
        display: flex;
        align-items: flex-start;
        margin-bottom: 1.5rem;
      }
    }
  }

  &__360 {

    margin-top: 79px;

    .title {
      color: $hoppler-orange;
      font-size: 25px;
      text-align: center;
    }

    .subtitle {
      text-align: center;
      margin-top: 36px;
      margin-bottom: 50px;

      .text {
        margin-bottom: 0;

        .divider {
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }
    }

    &-wrapper {
      width: 100%;
      height: 657px;
      @include media-breakpoint-down(sm) {
        height: 461px;
      }
    }
  }

  &__testimonials {
    padding: 2rem 0;
    background: #f9f9f9;
    display: block;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    img.quot {
      width: 51px;
      height: auto;
    }

  }

  &__carousel {
    display: none;
    margin-top: 3rem;
    position: relative;
    background: #f9f9f9;
    padding: 2rem 0;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    &--slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    &--img {
      height: 187px;
    }

    &--quote {
      width: 51px;
      margin: 1.5rem 0;
    }

    &--indicator {
      position: absolute;
      top: 5rem;
    }
  }

  &__charge {
    &--rent {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: center;
      }

      .details {
        @include media-breakpoint-down(sm) {
          text-align: center;
        }
      }

      img.rent {
        margin-right: 2rem;
        width: 136px;
        height: auto;
        @include media-breakpoint-down(sm) {
          margin-bottom: 2rem;
          margin-right: 0;
        }
      }
    }
    &--sale {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: center;
      }

      .details {
        @include media-breakpoint-down(sm) {
          text-align: center;
          margin-right: 0;
        }
      }

      img.rent {
        margin-right: 2rem;
        width: 136px;
        height: auto;
        @include media-breakpoint-down(sm) {
          margin-bottom: 2rem;
          margin-right: 0;
        }
      }
    }
  }

  &__cta {
    margin-top: 7rem;
    padding: 2rem;
    display: block;
    border-top: 1px solid gainsboro;

    &--text {
      display: block;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &--btn {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    @include media-breakpoint-down(sm) {
      // display: none;
      position: fixed;
      bottom: 0;
      background: white;
      width: 100%;
      z-index: 1;
      padding: 1rem 1.8rem 0.5rem 1.8rem;
    }
  }
}
