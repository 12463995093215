@import '../variables';

.hi-toast__container {
  position: fixed;
  z-index: 901;

  bottom: 12px;
  left: 12px;
}

.hi-toast {
  opacity: 1;
  // padding: 1rem;
  border: 1px solid $hi-divider-color;
  margin: 1rem;
  cursor: pointer;

  &:hover {
    border-color: black;
  }
}

.hi-toast__title {
  font-weight: 900;
}

.hi-toast__message {
  margin: 0;
  background-color: $hi-light-gray-bg;
}