@import '../variables';
@import '../utils';

$card-border-color: #d9d5d5;

.hi-rent-vs-buy {
  &__card {
    border: 1px solid $card-border-color;
  }

  &__control-label {
    font-size: 10px;
    min-height: 30px;
    text-transform: uppercase;
    color: $hi-light-gray-text-color;

    &.extra-space {
      margin-right: -8px;
    }
  }

  &__slider-value {
    color: $hoppler-blue;
    font-weight: 700;
  }

  &__calculated-number, &__result {
    font-size: 24px;
    font-weight: 500;
  }

  &__result {
    color: $hoppler-blue;

    &.animated {
      animation-duration: .75s;
      animation-name: rvbwobble;
    }
  }

  &__result-message {
    font-size: 80%;
    color: $hi-light-gray-text-color;
  }


  &__top-row {
    @include media-breakpoint-up(lg) {
      height: 128px;
    }
    margin-bottom: 16px;
    border-bottom: 2px solid #d9d5d5;
  }
  
  &__line-chart__canvas-container {
    min-height: 100%;
    width: 100%;
  }
}

@keyframes rvbwobble {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
