
.hi-pptl-buttons {
  display: flex;

  &--compact {
    display: flex;
    flex-wrap: wrap;

    .btn:first-child {
      width: 100%;
    }

    .btn:nth-child(2), .btn:nth-child(3) {
      flex: 1 1 auto;
      margin-top: 10px;
    }
  }
}
