@import '../utils';
@import '../mixins/scrollbar';

.hi-expando {
  display: flex;

  @include media-breakpoint-down(md) {
    -webkit-overflow-scrolling: scroll;
    overflow-x: scroll;

    @include hi-hide-scrollbar;
  }

  @include media-breakpoint-up(lg) {
    overflow-x: initial;
    flex-wrap: wrap;

    margin-left: -15px;
    margin-right: -15px;
  }

  &-item {
    min-width: 265px;
    min-height: 1rem;

    @include media-breakpoint-down(md) {
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }

    @include media-breakpoint-up(lg) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      padding: 0 15px;
    }
  }
}
