@import '../variables';

// sass file for holding any general styling
// for inputs

.hi-text-input {
  border: 1px solid $hi-filter-input-border-color;
  background-color: white;
  width: 100%;
  min-height: 40px;
  padding: 8px 12px;
  box-sizing: border-box;

  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;

  &.invalid {
    border-color: $hoppler-error-color;
  }
}

.hi-input-label {
  font-weight: 500;
  margin-bottom: 0;
  font-size: 16px;
}

.hi-text-area {
  resize: none;
  min-height: 120px;
  width: 100%;
  padding: 8px 10px;
}

.hi-checkbox {
  margin-right: 0.5rem;
}

.hi-btn-loading {
  opacity: 0.4;
}

.hi-error-help-text {
  margin-top: 8px;
  font-size: 15px;
  color: $hoppler-error-color;
}

.hi-location-chip {
  color: white;
  background-color: $hi-lighter-text-color;
  border-radius: 2px;
  padding: 6px 8px;
  font-weight: 400;
  font-size: 15px;
  margin-right: 4px;
  margin-bottom: 4px;
  cursor: pointer;

  &__label {
    margin-left: 8px;
  }
}

.hi-bootstrap-datepicker-wrapper {
  cursor: pointer;

  &__input {
    border-right: none;
  }

  &__append-text {
    background-color: white;
    color: $hi-light-gray-text-color;
  }

  &.invalid {
    * {
      border-color: $hoppler-error-color;
    }
  }
}

.hi-input-radio-toggle {
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-transform: capitalize;
  outline: none;
  border: 1px solid $hi-text-color;
  padding: 0.5rem 1.25rem;
  margin: 0 !important;
  color: $hi-text-color;
  background-color: white;

  width: 100%;
  text-align: center;

  &:nth-child(n+2) {
    border-left: none;
  }

  &.active {
    color: white;
    background-color: $hi-lighter-text-color;
  }

  &.disabled {
    opacity: 0.6;
  }

  label {
    margin-bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  input {
    display: none;
  }
}

.hi-input-radio-toggle-stackable {
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-transform: capitalize;
  outline: none;
  border: 1px solid $hi-text-color;
  padding: 0.5rem 1.25rem;
  color: $hi-text-color;
  background-color: white;

  width: 100%;
  text-align: center;

  &:nth-child(n+2) {

    @include media-breakpoint-up(md) {
      border-left: none;
    }
  }

  &.active {
    color: white;
    background-color: $hi-lighter-text-color;
  }

  &.disabled {
    opacity: 0.6;
  }

  label {
    margin-bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  input {
    display: none;
  }
}

.hi-currency-input {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}