@import 'variables';

.breadcrumb {
  padding-left: 0;
  padding-right: 0;
  margin: 1rem 0;
  font-size: 12px;
  margin-bottom: 5px;

  background-color: transparent;
}

.breadcrumb-item {
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: .25rem 0;
  
  letter-spacing: 1px;

  &.active, &:last-of-type {
    a {
      color: inherit;
      pointer-events: none;
      cursor: not-allowed;
      font-weight: bold;
    }
  }

  a {
    text-decoration: none;
  }
}

.card {
  border: none;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),
    0 1px 1px 0 rgba(0,0,0,.2),
    0 1px 3px 0 rgba(0,0,0,.2);
}

.form-control, input.form-control, textarea.form-control {
  &:focus {
    background-color: Button-links #e9f4ff;
  }

  &.invalid {
    border-color: $hoppler-error-color;
  }
}
