@import '../variables';
@import '../utils';
@import '../mixins/orange-after';
@import '../mixins//borders';

.hi-events-page {

  &__gradient_container {
    background-image: linear-gradient(to top, #eeeae8, var(--white));
    border-bottom: 5px solid $hoppler-orange;
  }

  &__event-container {
    @include hi-top-orange;
    @include hi-border-radius-smoothen(all);

    background-color: white;
    padding: 1rem 1.75rem;

    &__tabs {
      border-bottom: none;
      $nav-padding-x: 10px;

      label {
        margin-bottom: 0;
        cursor: pointer;
      }

      .nav-item {
        padding: 0 0.75rem;

        .nav-link {
          color: #333;
          font-size: 15px;
          font-weight: 500;
          border: none;

          &.active {
            color: $hoppler-blue;
          }
        }

      }
    }
  }

}

.hi-event-nh-heading {
  h2 {
    font-size: 20px;
    @include media-breakpoint-up(sm) {
      font-size: 31px
    }
  }
  h4 {
    font-size: 18px;
    @include media-breakpoint-up(sm) {
      font-size: 24px
    }
  }  
  p {
    font-size: 14px;
    @include media-breakpoint-up(sm) {
      font-size: 1rem
    }
  }  
}
