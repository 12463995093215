@import '../variables';
@import '../mixins/orange-after';

.hi-signup {
  min-height: 70vh;

  &__heading, &__heading--type-select {
    font-family: $hi-heading-font;
    font-weight: 500;
    font-size: 21px;
    margin-top: 2rem;

    @include media-breakpoint-up(md) {
      color: white;
      margin-bottom: 1.5rem;

      font-size: 32px;
    }
  }

  &__heading--type-select {
    @include media-breakpoint-down(sm) {
      text-align: center;
      @include hi-orange-after;

      &::after {
        margin-top: 1rem;
      }
    }
  }

  &__banner {
    @include media-breakpoint-up(md) {
      background-color: $hoppler-blue;
      padding-top: .5rem;
      padding-bottom: 1.5rem;
    }

    &__lead {
      font-size: 12px;

      @include media-breakpoint-up(md) {
        font-size: 22px;
        color: white;
      }
    }

    &.with-min-height {
      min-height: 228px;
      margin-bottom: -228px;
    }
  }

  &__breadcrumb {
    li a, &:last-of-type li {
      color: white;
    }

    li.breadcrumb-item:before {
      color: #ccc;
    }
  }

  &__type-card {
    @include media-breakpoint-down(sm) {
      box-shadow: none;
      padding: 1.75rem 0;

      border-bottom: 1px solid $hi-divider-color;
    }

    &--last {
      @include media-breakpoint-down(sm) {
        border-bottom: none;
      }
    }

    @include media-breakpoint-up(md) {
      height: 450px;
    }

    @include media-breakpoint-up(xl) {
      height: 380px;
    }

    .card-body {
      padding: 0;

      @include media-breakpoint-up(md) {
        padding-top: 2rem;
        padding: 2.5rem .5rem;
      }
    }
  }

  &__type-heading {
    font-size: 17px;
    margin-bottom: .75rem;

    @include media-breakpoint-up(md) {
      font-size: 22px;
    }

    font-weight: 500;
    line-height: 1.32;
    text-align: center;
  }

  &__feature-list {
    list-style: none;
    text-align: center;
    padding: 0;
    font-size: 13px;

    @include media-breakpoint-up(md) {
      margin-top: 2rem;
      font-size: 16px;
    }

    li {
      margin: .75rem 0;
      @include media-breakpoint-up(md) {
        margin: 1rem 0;
      }
    }
  }

  &__tabs {
    border-bottom: none;
    $nav-padding-x: 10px;

    &.with-fade {
      .nav-link {
        opacity: 0.5;
      }

      .nav-link.active {
        opacity: 1;
      }
    }

    label {
      margin-bottom: 0;
      cursor: pointer;
    }

    .nav-item {
      padding: 0.5rem $nav-padding-x;
      display: flex;
      align-items: center;

      .nav-link {
        color: #333;
        font-size: 15px;
        font-weight: 500;
        border: none;
        padding: .5rem 0;
        border-radius: 0;

        &.active {
          border-bottom: 4px solid #333;
          margin-bottom: -4px;
        }
      }

      &:not(:last-of-type) {
        &::after  {
          content: " ";
          position: relative;
          
          left: $nav-padding-x;

          font-weight: lighter;
          height: 1.25rem;

          // Fix weird bug where this gets reduced to 0.9px by the browser
          width: 1.1px;

          display: inline-block;
          background-color: #999;
        }
      }

      &:first-of-type {
        padding-left: 0;
      }
    }
  }

  &__form-shortcut {
    background-color: white;
    color: #333;

    position: sticky;
    bottom: 1rem;
    width: 100%;

    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__copy {
    margin-top: 1.5rem;
    display: none;
    border-bottom: 1px solid #efefef;
    padding-bottom: 0.5rem;

    @include media-breakpoint-up(md) {
      border-bottom: none;
    }
  }

  &__copy.tab-pane {
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  &__tab-heading {
    font-family: $hi-heading-font;
    font-size: 21px;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__copy-head {
    color: $hoppler-blue;
    font-weight: bold;
    display: block;
  }

  &__tab-radio {
    display: none;
  }

  &__tab-radio:checked + .hi-signup__copy {
    display: block;
  }

  &__form-card {
    @include media-breakpoint-down(sm) {
      box-shadow: none;
      margin-top: .5rem;
      padding: 1rem 0;
      border-top: 1px solid $hi-divider-color;
      border-radius: 0;

      .card-body {
        padding: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-top: -64px;
    }

    .card-body {
      @include media-breakpoint-up(lg) {
        min-height: 560px;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.hi-sign-up-customer-modal {

  min-width: 0;
  width: 100%;
  position: relative;

  @include media-breakpoint-up(md) {
    min-width: 480px;
    width: 480px;
    box-sizing: border-box;
  }

  .hi-modal {
    &__header {
      font-family: $hi-heading-font;
      
      @include media-breakpoint-up(md) {
        justify-content: center;
        text-align: center;
      }

      &__h4 {
        font-family: $hi-heading-font;
        font-size: 20px;

        @include media-breakpoint-up(md) {
          font-size: 32px;
          margin-top: 1.5rem;
        }
      }
    }

    &__header-close {
      position: absolute;
      top: 24px;
      right: 12px;

      @include media-breakpoint-up(md) {
        top: 12px;
      }
    }
  }

  .hi-type-caption {
    font-size: 14px;
  }
}
