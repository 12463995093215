@import '../variables';

.hi-result-filters {
}

.hi-filter-group {
  // &.checkbox-group {
  //   max-width: 320px;
  // }
}

.hi-filter-group__row {
  display: flex;
  flex-direction: row;
}

.hi-filter-group__label {
  font-size: 16px;
  font-weight: 500;
}

.hi-filter-group__radio {
  display: inline-block;
  margin-right: 1.25rem;
  margin-bottom: .25rem;

  input {
    margin-right: 0.5rem;
  }
}

.hi-filter-group__checkbox {
  margin-right: 1.25rem;

  input {
    margin-right: 0.5rem;
  }
}

.hi-filter-group__number {
  border: 1px solid $hi-filter-input-border-color;  

  min-height: 40px;
  padding: 8px;
  font-size: 16px;
  // changes to 50% because it breaks on IE if 100%
  width: 50%;

  &:focus {
    z-index: 4;
  }

  &.first {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.last {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
    
  }
}

.hi-filter-group__submit {
  font-size: 16px;
  font-weight: normal;
  color: white;
  background-color: $hoppler-blue;
  min-height: 40px;
  padding: .5rem 1rem;
  text-align: center;
  border: none;
  border-radius: 2px;
}