@import '../variables';


.hi-aux__nav-dropdown {
  border-radius: $border-radius;
  border: 1px solid $hi-divider-color;
  background-color: white;

  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.hi-aux__outer {
  @include media-breakpoint-down(sm) {
    background-color: white;
    border-top: 5px solid $hoppler-orange;
  }

  @include media-breakpoint-up(md) {
    padding-bottom: 1.5rem;
  }
}


.hi-aux__content {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
  background-color: white;
  
  @include media-breakpoint-up(md) {
    background-color: white;
    padding: 2.5rem;
    border-radius: $border-radius;
    border: 1px solid $hi-divider-color;
    border-top: 5px solid $hoppler-orange;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 0;
    margin-bottom: .5rem;
  }
}

.hi-aux__nav {
  list-style: none;
  padding-left: 0;

  li {
    margin: .5rem 0;
  }

  li a {
    color: #333;

    @include media-breakpoint-up(md) {
      font-weight: 500;
    }
    
    &:hover {
      color: #333;
    }
  }

  &--sub-nav {
    list-style: none;
    padding-left: .75rem;

    li a {
      padding: .5rem 0;
      padding-left: 12px;
      font-weight: normal;

      &:hover {
        border-left: 2px solid $hoppler-blue;
        padding-left: 10px;
      }
    }
  }
}