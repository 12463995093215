@import '../variables';

.hi-login-form {
  background-color: white;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  border-radius: 3px;
  box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.08), 
    0 0 0 1px rgba(6, 44, 82, 0.1);

  text-align: center;
  
  max-width: 480px;
}

.hi-login-form__heading {
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

.hi-login-form__remember-me-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hi-login-form__show-password, .hi-login-form__sign-up,
.hi-login-form__forgot-password {
  font-size: 14px;
}