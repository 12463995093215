@import '../variables';
@import '../mixins/scrollbar';

.hi-map-element-host__mobile {
  position: absolute;
  height: 100%;
  width: 100%;
}

.hi-properties-map__property-page {
  position: relative;
  height: 240px;
  width: 100%;

  @include media-breakpoint-up(sm) {
    height: 480px;
  }
}

.hi-properties-map__results-page {
  position: relative;
  height: 100%;
  width: 100%;

  .hi-properties-map__map-container {
    @include media-breakpoint-down(lg) {
      // height: 480px !important;
    }
  }
}


.hi-properties-map__map-container {
  display: none;
  height: 240px;
  width: 100%;
  position: absolute;
  z-index: 5;
  border-radius: $border-radius;

  @include media-breakpoint-up(sm) {
    height: 480px;
  }

  @include media-breakpoint-up(md) {
    height: 100%;
  }

  border: 1px solid #c0c0c0;

  &.visible {
    display: block;
  }

  &.results-page {
    height: 100%;
  }
  
  &__card-scroll {
    position: absolute;
    z-index: 10;

    bottom: 45px;
    padding-left: 15px;
    width: 100%;

    display: flex;
    flex-direction: row;

    overflow: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    @include hi-hide-scrollbar;

    .hi-properties-map__info-window__property-card {
      margin-right: 10px;
      margin-bottom: 8px;

      box-shadow: 0px 2px 8px 0px $hoppler-shadow-color;

      @include media-breakpoint-up(lg) {
        margin-right: 16px;
        box-shadow: none;
      }

    }
  }
}

.hi-properties-map__open-map-button {
  position: absolute;
  height: 240px;
  width: 100%;
  z-index: 2;

  @include media-breakpoint-up(sm) {
    height: 480px;
  }

  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.hi-properties-map__open-map-button-img {
  height: 240px;
  width: 100%;
  object-fit: cover;
  border-radius: $border-radius;

  @include media-breakpoint-up(sm) {
    height: 480px;
  }
}

.hi-properties-map__open-map-button-label {
  position: absolute;
  z-index: 3;
  font-size: 16px;
  font-weight: 700;
  color: $hoppler-blue;
  transform: translateY(36px);
  
  // this is for IE 11 and below
  left: calc(50% - 100px);

  @include media-breakpoint-up(sm) {
    transform: translateY(58px);
  }
}

.hi-properties-map__nearby-places {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 99;
}

.hi-properties-map__nearby-places-toggle {
  background-color: white;
}

.hi-properties-map__nearby-places-option {
  cursor: pointer;

  &.active {
    background-color: $hoppler-blue;
    color: white !important;
    pointer-events: none;
  }
}

.hi-properties-map__info-window-content {
  .info {
    .name {
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
    }

    .contact, .full-address {
      display: block;
      font-family: $hi-body-font;
      font-weight: 400;
      font-size: 13px;

      margin-bottom: 0;
      line-height: 16px;
    }
  }

  .image {
    width: 100%;
    height: 80px;
  }
}

.hi-properties-map__info-window-img {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.hi-properties-map__info-window {
  &__property-card {
    color: #333;
    min-width: 300px;
    max-width: 300px;
    border-radius: $border-radius;

    background-color: white;
    display: flex;
    flex-direction: row;

    @include media-breakpoint-up(sm) {
      min-width: 300px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 400px;
    }

    &__details {
      padding: 0.5rem 0.75rem;
      width: 100%;
  
      @include media-breakpoint-up(md) {
        padding: 0.5rem 1.25rem 1rem 1.25rem;
      }
    }

    // these contain mobile overrides
    @include media-breakpoint-down(md) {
      $card-height: 100px;
      height: $card-height;

      margin-right: 8px;

      &__img-container {
        width: 100px;
      }

      .hi-property-card__photo {
        height: $card-height;
        min-height: 0;
        width: 100px;

        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        border-top-right-radius: 0;
      }

      .hi-property-card__bottom-group {
        flex-direction: column;
      }

      .hi-property-card__title {
        margin-bottom: 4px;
        min-height: 0;
        font-size: 16px;

        text-overflow: ellipsis;
        white-space: nowrap;
        width: 180px;
        overflow: hidden;
      }

      .hi-property-card__price {
        font-size: 14px;
      }

      .hi-property-card__info {
        font-size: 11px;
      }

      .hi-property-card__vl-button {
        border: none;
        padding: 4px 0;
        font-size: 12px;
        margin-top: -4px;
      }
    }

    @include media-breakpoint-up(lg) {
      flex-direction: column;
      max-width: 350px;      
    }
  }

  &__controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid $hi-divider-color;
    padding-top: 0.5rem;

    &__btn {
      border: none;
      font-size: 15px;
      outline: none !important;
      box-shadow: none !important;
    }
  }

}