@import '../variables';
@import '../utils';
@import '../mixins/borders';

.hi-fast-track-form {
  background-color: white;
  border: 1px solid $hi-divider-color;

  @include hi-top-orange;

  .section-disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &__input-radio-toggle-stackable {
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-transform: capitalize;
    outline: none;
    border: 1px solid $hi-text-color;
    padding: 0.5rem 1.25rem;
    color: $hi-text-color;
    background-color: white;

    width: 100%;
    text-align: center;

    &:nth-child(n+2) {

      @include media-breakpoint-up(md) {
        border-left: none;
      }
    }

    &.active {
      color: white;
      background-color: $hi-lighter-text-color;
    }

    &.disabled {
      opacity: 0.6;
    }

    label {
      margin-bottom: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input {
      display: none;
    }
  }

  &__input-radio-toggle {
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-transform: capitalize;
    outline: none;
    border: 1px solid $hi-text-color;
    padding: 0.5rem 1.25rem;
    margin: 0 !important;
    color: $hi-text-color;
    background-color: white;

    width: 100%;
    text-align: center;

    &:nth-child(n+2) {
      border-left: none;
    }

    &.active {
      color: white;
      background-color: $hi-lighter-text-color;
    }

    &.disabled {
      opacity: 0.6;
    }

    label {
      margin-bottom: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input {
      display: none;
    }
  }

  &__form-group-label {
    font-weight: 500;
    margin-bottom: 0;
    font-size: 16px;
  }

  &__form-group-sublabel {
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 15px;
  }

  &__location-search {
    position: relative;

    &__chip-container {
      margin-bottom: 6px;
    }

    &__textarea-container {
      background-color: white;
      width: 100%;
      border: 1px solid $hi-filter-input-border-color;
      margin: 0;
      padding: 6px;
      border-radius: $border-radius;

      &.focused {
        border-color: $hoppler-blue;
      }
    }

    &__text-area {
      border: none;
      background: transparent;
      width: 100%;
      height: 100%;
      outline: none;
      resize: none;
    }

    &__search-results {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      min-height: 100px;
      background-color: white;
      z-index: 10;
      box-shadow: 0px 1px 1px 0px $hoppler-shadow-color;

      &.visible {
        display: block;
      }      
    }

    &__result {
      font-size: 16px;
      padding: 4px 16px;
      margin: 0;
      min-height: 32px;
      cursor: pointer;
  
      &:hover {
        background-color: $hoppler-blue;
        color: white;
      }
    }
  }

  &__message {
    resize: none;
    min-height: 120px;
    width: 100%;
    padding: 8px 10px;
  }
}

.hi-fast-track-information {
  &__h1 {
    font-size: 24px;
    font-weight: 500;
    font-family: $hi-heading-font;

    @include media-breakpoint-up(md) {
      font-size: 32px;
    }
  }

  &__h1-caption {
    font-size: 15px;

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
  }
  &__field {
    // min-height: 40px;
  }

  &__designation-types {
    @include media-breakpoint-up(lg) {
      width: 60%;
    }

    &--widget-mode {
      @include media-breakpoint-up(lg) {
        width: 90%;
      }

      @include media-breakpoint-up(xl) {
        width: 80%;
      }
    }
  }

  &__button-container {
    .btn {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: initial;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
      }
    }
  }

  &__designation-type {
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-transform: capitalize;

    outline: none;
    border: 1px solid $hi-text-color;
    padding: 0.5rem 1.25rem;
    margin: 0 !important;
    color: $hi-text-color;
    background-color: white;

    width: 100%;
    text-align: center;

    &:nth-child(n+2) {
      border-left: none;
    }

    &.active {
      color: white;
      background-color: $hi-lighter-text-color;
    }

    label {
      margin-bottom: 0;
    }

    input {
      display: none;
    }
  }

  // This is used because there was a requirement to make
  // the error message not push down the proceed button
  &__designation-spacer {
    height: 24px;
    padding-top: 2px;
  }

  &__submit-section {
    background-color: $hi-light-gray-bg;
    border-bottom: 1px solid $hi-divider-color;

    @include media-breakpoint-up(md) {
      border-top: 1px solid $hi-divider-color;
    }

    &__h3 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
    }

    &__subhead {
      font-size: 15px;
    }

    &__p {
      font-size: 15px;
      margin: 0;
    }
  }
}

.hi-fast-track-property-preferences {

  &__form {
    &.disabled {
      opacity: 0.6;
    }
  }
}