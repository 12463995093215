
.hi-toggle-switch {
  display: flex;
  align-items: center;

  height: 2rem;
  min-width: 2rem;

  user-select: none;
  cursor: pointer;

  &__label {
    display: inline-block;
    margin-left: 8px;
  }
}

a.hi-toggle-switch {
  color: #333;
  text-decoration: none;
}
