/*
TYPOGRAPHY

Main site typograpy styles. For the AMP site, a trimmed down set of styles is
loaded via typography-amp.scss.
*/

@import './typography-core';

@import './variables';
@import './mixins/type';

// @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

// Font Awesome
$fa-font-path: '~../../node_modules/@fortawesome/fontawesome-free/webfonts';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@font-face {
  font-family: 'Tiempos Headline';
  src: url('../../public/fonts/TiemposHeadline-Medium.otf') format('opentype');
  font-weight: 500;
  font-display: swap;
}

.hi-color-blue {
  color: $hoppler-blue !important;
}

.hi-color-orange {
  color: $hoppler-orange !important;
}

.hi-color-black {
  color: black !important
}

.hi-default-font {
  font-family: 'Tiempos Headline'
}
