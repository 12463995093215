@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/breakpoints';

@mixin hi-readability-shadow-bg-inverted {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);

  $gradient-start: rgba(0, 0, 0, 0.75);
  $gradient-end: transparent;
  /* IE10+ */
  background-image: -ms-linear-gradient(top, $gradient-end 0%, $gradient-start 100%);

  /* Mozilla Firefox */
  background-image: -moz-linear-gradient(top, $gradient-end 0%, $gradient-start 100%);

  /* Opera */
  background-image: -o-linear-gradient(top, $gradient-end 0%, $gradient-start 100%);

  /* Webkit (Safari/Chrome 10) */
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, $gradient-end), color-stop(100, $gradient-start));

  /* Webkit (Chrome 11+) */
  background-image: -webkit-linear-gradient(top, $gradient-end 0%, $gradient-start 100%);

  /* W3C Markup */
  background-image: linear-gradient(to bottom, $gradient-end 0%, $gradient-start 100%);
}

@mixin hi-center-crop-bg($image) {
  background-image: $image;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@mixin modal-fullscreen() {
  @if variable-exists("hi-amp") {
    padding: 0;
  }
  @else {
    padding: 0 !important; // override inline padding-right added from js
  }
  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-body {
    overflow-y: auto;
  }

}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .modal-fullscreen#{$infix} {
      @include modal-fullscreen();
    }
  }
}
