@import '../variables';

.hi-viewing-list-completed-page {
  background-color: $hi-light-gray-bg;

  &__card {
    box-shadow: 0px 1px 1px 0px $hoppler-shadow-color;
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    background-color: white;
  
    &__h1 {
      font-size: 32px;
      font-family: $hi-heading-font;
      margin-bottom: 0;
    }

    &__p {
      font-size: 16px;
      margin: 0;
      margin-top: 0.25rem;
    }

    &__btn {
      width: 240px;
      margin-top: 3rem;
      
    }
  }
}