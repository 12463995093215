@import '../variables';

.hi-kumita-page {
    .bottom-border {
        border-bottom: 1px solid map-get($theme-colors, 'secondary');
    }
    .thick-bottom-border {
        border-bottom: 3px solid map-get($theme-colors, 'secondary');
    }
    h1 {
        font-family: Tiempos Headline, serif;
        font-size: 42px;
        font-weight: 600;
    }
    h3 {
        text-transform: uppercase;
        color: map-get($theme-colors, 'secondary');
        font-size: 15px;
        font-weight: 500;
    }
    h4 {
        font-family: Tiempos Headline, serif;
        font-size: 32px;
        font-weight: 500;
    }
    p {
        font-size: 16px;
    }
}
