
.hi-fbcc-loader {
  cursor: pointer;
  outline: none;
  position: fixed;
  right: 18pt;
  bottom: 18pt;
}

.fb_customer_chat_bounce_in_v2 {
  min-height: 200px !important;
}
