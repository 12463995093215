@import '../variables';
@import '../utils';

.hi-rewards-page {

  &__cover-photo {
    width: 100%;
    object-fit: cover;
    margin-top: 1.5rem;
    
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

  }

  &__how-it-works {
    background-color: white;
    padding: 2rem 1rem;

    &__h2 {
      font-size: 22px;
      font-weight: 500;
      padding-left: 24px;
      margin-bottom: -8px;
    }

    &__highlighted {
      color: $hoppler-blue;
    }

    &__step-container {
      &.step-1 {
        order: 1;
      }

      &.step-2 {
        order: 2;

        @include media-breakpoint-up(lg) {
          order: 3;
        }
      }

      &.step-3 {
        order: 3;

        @include media-breakpoint-up(lg) {
          order: 2;
        }
      }

      &.step-4 {
        order: 4;
      }
    }

    &__step {
      display: flex;
      flex-direction: row;

      &__index {
        min-width: 60px;
        width: 60px;
        color: $hoppler-orange;
        font-size: 32px;
        font-family: $hi-heading-font;
        font-weight: 500;
        text-align: center;
      }

      &__details {
        padding-top: 8px;
        font-size: 16px;
        line-height: 1.63;

        p {
          margin-bottom: 0;
          margin-top: 4px;
          line-height: 1.63;
        }

        &__title {
          font-size: 22px;
          color: $hoppler-orange;
        }
      }
    }
  }

  &__start-earning-points {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__h2 {
      font-size: 25px;
      font-weight: 500;
      font-family: $hi-heading-font;
      text-align: center;
    }

    &__hr {
      width: 100px;
      height: 5px;
      border-top: 5px solid $hoppler-orange;
      margin: 0 auto;
      margin-bottom: 12px;
      display: block;
    }

    &__sign-up-here {
      font-size: 14px;
    }

    &__for-questions {
      font-size: 14px;
    }
    
  }

  &__terms-and-conditions {
    border-top: 1px solid $hi-divider-color;

    &__h4 {
      font-size: 15px;
      text-transform: uppercase;
      color: $hi-light-gray-text-color;
      margin-bottom: 0.75rem;
    }

    &__list {
      padding-left: 1rem;
    }

    &__list-member {
      margin-bottom: 0.5rem;
    }
  }

}