@import '../variables';
@import '../utils';
@import '../mixins/type';


.hi-nguide-village {
  &__hero-group {
    display: flex;
    flex-direction: column;
  }

  &__hero-image {
    display: block;
    width: 100%;
    object-fit: cover;

    order: 5;
    height: 320px;

    @include media-breakpoint-up(md) {
      height: 200px;
    }

    @include media-breakpoint-up(lg) {
      height: 384px;
    }
  }
  
  &__heading-row-container {
    order: 8;

    @include media-breakpoint-up(md) {
      order: 2;
    }
  }

  &__heading-row {
    display: flex;
    flex-direction: column;
    margin-top: 1.25rem;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;
    }
  }

  &__h1 {
    font-family: $hi-heading-font;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.28;

    margin-top: 0;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
      font-size: 32px;
    }
  }

  &__view-props-link {
    font-size: 14px;
  }

  &__content-heading {
    margin-bottom: 1rem;

    @include media-breakpoint-down(sm) {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.53;
    }

    @include media-breakpoint-up(md) {
      @include hi-type-headline;
      font-weight: 500;
    }
  }

  &__content-body {
    line-height: 1.8;
    text-align: left;

    @include media-breakpoint-up(md) {
      line-height: 2;
    }
  }

  &__section-heading {
    font-family: $hi-heading-font;
    font-size: 19px;
    color: $hoppler-orange;

    @include media-breakpoint-up(md) {
      font-size: 25px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 32px;
    }
  }

  &__blog-header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 2.5rem;
      margin-top: 1.5rem;
    }
  }
}


////////////////////////////////////////////////////////////////////////////////
// TRIMMED-DOWN OLD STYLES
// To be removed once the city page is moved to the new design
////////////////////////////////////////////////////////////////////////////////


#hiNeighborhoodCarousel {
  height: 70vh;
  width: auto;
  background-color: #777;

  img {
    height: 70vh;
    object-fit: cover;
  }
}

#intro, #attractions, #properties {
  position: relative;
  bottom: 96px;
}

.carousel-plaque {
  text-transform: uppercase;
  border: 1px solid white;
  background: rgba(57, 60, 67, 0.6);
  // background: rgba(0, 0, 0, 0.45);
  // background: rgba(57, 60, 67, 0.4);
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  pointer-events: none;
}

.carousel-plaque__title {
  color: white !important;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: 5px;
  font-size: 24px;

  @include media-breakpoint-up(md) {
    font-size: 36px;
  }
}

.carousel-plaque__subtitle {
  pointer-events: all;
  color: #f6f6f6 !important;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 2px;
}

a.carousel-plaque__subtitle:hover {
  color: white;
  text-decoration: underline;
}

.carousel-middle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @include media-breakpoint-down(sm) {
    max-width: 80%;
  }
}

.hi-neighborhood-guide__carousel-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // background-color: hotpink;
}

.hi-neighborhood-guide__nav {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hi-neighborhood-guide__nav__item {
  padding: 0 1rem;
}
