
.hi-mag-article-list-item {
  &, &:hover {
    color: #333;
  }

  &__image {
    min-height: 186px;
    height: 186px;
    display: block;
  }
}
