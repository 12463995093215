@import '../../../mixins/borders';
@import '../../../mixins/card';

.hi-magazine-ad {
  @include hi-comp-card;
  @include hi-top-orange;
  @include hi-border-radius-smoothen(all);

  width: 100%;
  height: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 9px;
  }

  &__p {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 2px;
  }

  &__a {
    font-size: 14px;
    font-weight: normal;
  }

  &__image-a {
    img {
      width: 100%;
      object-fit: cover;
    }
  }

}