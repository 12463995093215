/*
TYPOGRAPHY-CORE

Typography styles that are reusable between the main site and AMP pages.
*/

@import './variables';
@import './mixins/type';

body {
  font-family: $hi-body-font, sans-serif;
}

.hi-fat-heading {
  @include hi-type-title;
  font-family: $hi-heading-font, serif;
  text-align: center;
}

.hi-text-slightly-shrunken {
  font-size: 14px;
}

.hi-type-heading {
  @include hi-type-heading;
}

// Mostly equivalent to mat-headline and mat-caption
.hi-type-headline {
  @include hi-type-headline;
}

.hi-type-headline-alt {
  @include hi-type-headline-alt;
}

.hi-type-caption {
  @include hi-type-caption;
}

.hi-type-title {
  @include hi-type-title;
}

.hi-type-caps {
  @include hi-type-caps;
}

p {
  line-height: 1.8;
}


////////////////////////////////////////////////////////////////////////////////
// Styles from the Zeplin style guide, text style catalog
////////////////////////////////////////////////////////////////////////////////

.hi-type-heading-desktop-ink {
  @include hi-type-heading-desktop-ink;
}

.hi-type-heading-responsive-ink {
  @include hi-type-heading-responsive-ink;
}

.hi-type-display-medium-desktop-ink {
  @include hi-type-display-medium-desktop-ink;
}

.hi-type-caption-desktop-subdued {
  @include hi-type-caption-desktop-subdued;
}

.hi-type-caption-mobile-ink {
  @include hi-type-caption-mobile-ink;
}
