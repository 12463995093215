@import '../variables';

.hi-footer {
  font-size: .875rem;

  &__seo-column {
    &__head {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 16px;
    }
  }
}


.hi-footer__seo-link-header {
  font-weight: lighter;
  font-size: 1rem;
}

.hi-footer__seo-link {
  display: block;
  font-size: .875rem;
  margin-bottom: .8rem;
}

.hi-footer__accordion {
  margin-bottom: .75rem;
  margin-top: .75rem;
}

.hi-footer__accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 40px;
  margin-bottom: 1rem;

  border-bottom: 1px solid #333;

  
}

.hi-footer__accordion-content {
  padding-bottom: 1.5rem;
}

.hi-footer__accordion-toggle {
  background: none;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  
  &.opened {
    .fa {
      transform: rotate(180deg);
    }
  }
}

.hi-footer__main {
  background-color: #f3f3f3;
  padding: 2rem 0;
  color: #333;

  @include media-breakpoint-up(md) {
    padding: 3rem 0;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 3rem;
  }

  a, a:hover {
    color: #333
  }

  a.hi-footer__blue-anchor {
    color: $hoppler-blue;
  }
}

.hi-footer__logo {
  max-width: 140px;
  margin-bottom: .75rem;

  @include media-breakpoint-up(md) {
    max-width: 25vw;
    margin-bottom: 1.5rem;
  }

  @include media-breakpoint-up(lg) {
    max-width: 20vw;
  }

  height: auto;
}

.hi-footer__social-media-links {
  display: flex;

  margin-bottom: 1.5rem;

  @include media-breakpoint-down(sm) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }

  .flex-item {
    @include media-breakpoint-up(md) {
      margin-right: 1rem;
    }

    &:last-child {
      margin-right: 0;
    }

    .social-media-icons {
      height: 30px;
      width: 30px;

      @include media-breakpoint-up(md) {
        height: 40px;
        width: 40px;
      }
    }
  }
}

.hi-footer__links-header {
  font-size: 15px;
  display: block;

  margin-bottom: 1.5rem;
}

.hi-footer__links-header--apps {
  margin-bottom: 1rem;
}

.hi-footer__site-links {
  // .mt-4.border-top.border-white
  // border-bottom: 1px solid white;
  padding-bottom: 1rem;

  @include media-breakpoint-up(md) {
    padding-bottom: 0;
  }
}

.hi-footer__site-links--desktop {
  display: none;
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.hi-footer__site-links--mobile {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.hi-footer__mobile-app-logos {
  display: flex;
  padding-bottom: 0.5rem;

  @include media-breakpoint-up(sm) {
    padding-bottom: 0;
  }
}

.hi-footer__mobile-app-logo {
  width: 117px;
  height: 45px;
}

.hi-footer__mobile-app-logo--apple {
  padding: 6px 0;
  margin-left: -8px;
}

