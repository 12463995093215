@import 'utils';

.nav-dropdown {
  // only show on laptop and above
  @include media-breakpoint-down(md) {
    display: none;
  }

  text-transform: capitalize;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    margin-left: 3px;
  }

  &:hover {
    background-color: #f6f6f6;
  }

  // &:active .nav-dropdown__category-container,
  // &:hover .nav-dropdown__category-container {
  //   visibility: visible;
  // }
}

.nav-dropdown__category-container {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);

  width: 200px;
  position: absolute;
  background-color: white;

  display: flex;
  flex-direction: column;

  margin-top: 20px;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  visibility: hidden;

  left: 0;
  top: 2rem;

  &.nav-dropdown__category-container--user {
    margin-top: 19px;
  }

  &.visible {
    visibility: visible;
  }
}

.nav-dropdown__category {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-left: 1rem;
  padding-right: 1.5rem;

  height: 2.5rem;
  font-size: 0.875rem;

  &:active,
  &:hover {
    background-color: #F6F6F6;
  }


  &:active > .nav-dropdown__city-container,
  &:hover > .nav-dropdown__city-container {
    visibility: visible;
  }

  i, span {
    color: #333;
  }

  &.nav-dropdown__category--user-menu {
    color: #333;
    text-decoration: none;
  }
}

.nav-dropdown__city-container {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);

  width: 180px;
  position: absolute;
  background-color: white;

  display: flex;
  flex-direction: column;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  top: 0;
  left: 180px;

  visibility: hidden;
  z-index: 10;
}

.nav-dropdown__city {
  text-decoration: none;
  text-align: left;
  padding-left: 1rem;

  height: 2.5rem;
  display: flex;
  align-items: center;

  span {
    color: #333;
  }

  &:active,
  &:hover {
    background-color: #F6F6F6;
  }
}

.nav-dropdown__user-container {
  position: absolute;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  background-color: white;
  padding: 8px 0;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  min-width: 180px;
  text-align: left;
}

.nav-dropdown__user-action {
  display: block;
  padding: 0.5rem 1rem;
  color: #333;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background-color: #F6F6F6;
  }
}
