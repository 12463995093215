
@mixin hi-scrollable-card-container {
  &__side-scroll {
    padding: 0 15px .5rem 15px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }

    &__content {
      display: flex;

      &::after {
        min-width: 1px;
        margin-left: -1px;
        height: 32px;
        content: ' ';
      }
    }

    .hi-property-card--wide-mode:last-of-type {
      @include media-breakpoint-up(md) {
        margin-right: 15px;
      }
    }
  }

  .hi-property-card {
    min-width: 320px;
    margin-right: 1rem;
  }
}
