@import '../variables';
@import '../utils';

.hi-contact-us-page {

  &__h1 {
    font-family: $hi-heading-font;
    font-size: 32px;
  }

  &__h2 {
    font-family: $hi-heading-font;
    font-size: 32px;
  }

  &__form-card {
    border: solid 1px $hi-divider-color;

    @include hi-top-orange();
    @include hi-border-radius-smoothen(all);

    background-color: white;
    padding: 2rem 2.5rem;
  }

  &__card {
    border: solid 1px $hi-divider-color;

    @include hi-border-radius-smoothen(all);
    
    background-color: white;
    padding: 1.5rem 2rem;
  }
}

.hi-contact-us-form {

  &__submit-btn {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  } 
}