@import '../variables';

.hi-pagination {
  margin: 1.5rem 0;

  .page-item:first-of-type,
  .page-item:last-of-type {
    .page-link {
      border-radius: 50%;
      border: 1px solid #333;
      background-color: white;

      &.disabled {
        border: 1px solid $hi-divider-color;
        color: #999;
      }
    }
  }

  .page-item {
    margin: 0 4px;
  }

  .page-link {
    background-color: transparent;
    border: none;
    color: #333;
    font-size: .875rem;
  }
}
