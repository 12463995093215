@import '../variables';

.hi-booking__promo-sticker__expanded {
  transition: 300ms;
  width: 100%;
  border-top: 4px solid $hoppler-orange;
  height: 3rem;
  background: white;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 3;
  bottom: 0;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  .banner-content {
    position: absolute;
    bottom: 0;

    &__text {
      font-family: $hi-heading-font;
      position: absolute;
      bottom: 0.6rem;
      text-decoration: none;
      cursor: pointer;
      display: flex;
      color: $hi-text-color;
      margin-bottom: 0;

      @include media-breakpoint-down(lg) {
        font-size: 12px;
        bottom: 0.8rem;
      }    

      .highlighted-text {
        color: $hoppler-orange;
        margin: 0 0.4rem;
      }
      
      .view-deals {
        font-family: $hi-body-font;
        color: $hoppler-orange;
        font-weight: 500;
        margin-top: 1px;
        font-size: 13.5px;
        margin-left: 0.5rem;

        &__icon {
          font-size: 10px;
        }

        @include media-breakpoint-down(lg) {
          font-size: 11px;
          bottom: 0.8rem;
        }  
      }
    }

    .house-image {
      cursor: pointer;
    }
  }
}

.hi-booking__promo-sticker__collapsed {
  transition: 300ms;
  width: 105px;
  height: 81px;
  background: transparent;
  position: fixed;
  right: -15rem;
  bottom: 7rem;
  @include media-breakpoint-down(sm) {
    display: none;
  }

  .close-btn {
    position: absolute;
    top: 0;
    background: $hoppler-orange;
    right: 1.1rem;
    outline: none;
    border: none;
    height: 25px;
    width: 25px;
    z-index: 25;
    border-radius: 50%;

    i {
      margin-left: 2px;
      margin-top: 4px;
    }
  }
}