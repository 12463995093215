@import './variables';

.hi-gray-bg {
  background-color: #f5f5f5;
}

.hi-property-card-grid {
  .hi-property-card {
    margin-bottom: 2rem;
  }
}

.hi-hidden {
  display: none !important;
}

.hi-rounded-corners {
  border-radius: $border-radius;
}

.hi-card-orange-top {
  border-top: 5px solid $hoppler-orange;
}

.hi-fa-1p5x {
  font-size: 1.5em;
}

.hi-image-preload-pixel {
  width: 1px;
  height: 1px;
  transform: scale(1%);
  position: absolute;
  top: 0;
  left: 0;
}


ul.hi-orange-bullets {
  list-style: none;
}

ul.hi-orange-bullets li::before {
  content: "\2022";
  color: #ee590b;
  font-weight: bold;
  display: inline-block;
  width: 1em; 
  margin-left: -1em;
}

.hi-orange-bottomline {
  border-bottom: 4px solid black;
  width: 100%;
}
