
.hi-booking-image-picker {
  border: dashed 2px #eee;
  height: 220px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .opacity-30 {
    opacity: 0.3;
  }

  &__remove-btn {
    z-index: 4;
  }

  &__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  &__error-message {
    position: absolute;
    top: 1rem;
    z-index: 2;
  }

  &__id-logo {
    width: 74px;
    height: 58px;
    object-fit: contain;
    z-index: 1;
  }

  &__label {
    display: block;
    margin: 12px 0;
    text-transform: uppercase;
  }

  &__file-input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 3;

    cursor: pointer;
  }

  &.invalid {
    border-color: lighten(map-get($theme-colors, 'danger'), 25%);
  }
}
