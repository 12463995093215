@import '../variables';

@mixin hi-comp-card {
  background-color: white;
  border: 1px solid #d4d1d1;
  border-radius: .25rem;
}

@mixin hi-colored-card {
  // border: 1px solid $hi-divider-color;
  border-top: 5px solid $hoppler-orange;
  // box-shadow: none;

  border-radius: .25rem;
  // border-bottom-left-radius: .25rem;
  // border-bottom-right-radius: .25rem;

  transition: opacity .25s ease;
}
