@import "../variables";
@import '../mixins/type';
@import "../utils";

.hi-booking__navbar {
  border-bottom: 1px solid #eee;
  
  @include media-breakpoint-down(sm) {
    height: $navbar-height-sm;
  }

  &-logo {
    height: 51px;
    margin-top: 4.8px;
    @include media-breakpoint-down(sm) {
      height: 32px;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  &-dropdown {
    .toggle-dropdown {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      @include media-breakpoint-down(sm) {
        font-size: 15px;
      }
    }
  }

  .dropdown-menu {
    left: -6rem;

    .dropdown-item {
      cursor: pointer;
      background: white;
      color: black;
    }
  }

  .inquire-btn {
    @include media-breakpoint-down(sm) {
      font-size: 15px;
    }
  }

  &-navlist {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &-dropdown {
    display: none;
    background: transparent;
    outline: 0;
    border: 0;

    @include media-breakpoint-down(md) {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
    }
  }
}

.hi-booking {
  scroll-behavior: smooth;

  .text-14, i.fas {
    font-size: 14px
  }

  .hi-booking__section-header {
    font-family: "Tiempos Headline", serif;
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    margin-top: 2rem;
    margin-bottom: 3rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.61;
    letter-spacing: normal;
    @include media-breakpoint-down(xs) {
      font-size: 25px;
    }
  }

  &__row {
    @include media-breakpoint-down(sm) {
      margin-top: -15rem;
    }
  }

  &__landing {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 550px;
    background: url('https://hoppler-inc.imgix.net/hoppler/pages/static/booking-system/banner.png?auto=format&w=1920');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;

    @include media-breakpoint-down(lg) {
      background-size: contain;
    }

    @include media-breakpoint-down(xs) {
      background: url('https://hoppler-inc.imgix.net/hoppler/pages/static/booking-system/banner-mobile.png?auto=format');
      background-position: top;
      background-repeat: no-repeat;
      background-position-y: bottom;
      background-size: cover;
      height: 520px;
    }

    &-tag {
      width: 44px;
      margin-right: 0.5rem;
    }

    &-title  {
      display: block;
      font-size: 40px;
      line-height: 1.2;
      margin-bottom: 0;
      font-family: "Tiempos Headline", serif;
      font-weight: 500;
      @include media-breakpoint-down(xs) {
        font-size: 28px;
      }
    }

    &-text {
      margin-top: 0.5rem;
      font-size: 17px;
    }

    &-subtitle  {
      display: block;
      margin-top: 1.5rem;
      margin-bottom: 0;
      color: $hoppler-orange;
      font-size: 22px;
      font-weight: 500;

      @include media-breakpoint-down(sm) {
        font-size: 20px;
      }
    }
  }

  &__expect {
    padding-top: 5rem;
    @include media-breakpoint-down(sm) {
    padding-top: 0;
    }

    &-col {
      text-align: center;
      @include media-breakpoint-down(md) {
        margin-bottom: 3rem
      }
  
      .subtitle {
        font-size: 18px;
        font-weight: 500;

        @include media-breakpoint-down(md) {
          font-size: 22px;
          margin-bottom: 0;
        }
      }
  
      &-title {
        display: block;
        font-size: 42px;
        margin-bottom: 0;
        font-family: "Tiempos Headline", serif;
        font-weight: 500;
        color: $hoppler-orange;
      }
    }
  }

  &__logos {
    margin-top: 5rem;
    width: 100%;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &-header {
      font-family: "Tiempos Headline", serif;
      text-align: center;
      font-size: 32px;
      font-weight: 500;
      margin-top: 2rem;
      margin-bottom: 0;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.61;
      letter-spacing: normal;
      @include media-breakpoint-down(xs) {
        font-size: 25px;
      }
    }

    &-row {
      margin-top: 50px;
      margin-bottom: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-col {
      margin: 0 1rem;
      width: 200px;
      margin-bottom: 1rem;
      display: flex;
      justify-content: center;
    }
  }

  &__carousel {
    display: none;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &__carousel-logo {
    display: none;
    width: 95%;

    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: flex-start;
      margin-top: 3rem;
      margin-bottom: 5rem;
      overflow-y: auto;
    }

    .carousel-item-logo {
      margin: 0 0.5rem;
      min-width: 200px;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &::-webkit-scrollbar {
      height: 2.5px;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: grey; 
      border-radius: 10px;
    }
  }

  &__works {
    padding-top: 5rem;
    @include media-breakpoint-down(sm) {
      padding-top: 1rem;
    }

    &-section-header {
      font-family: "Tiempos Headline", serif;
      text-align: center;
      font-size: 32px;
      font-weight: 500;
      margin-top: 2rem;
      font-stretch: normal;
      margin-bottom: 0;
      font-style: normal;
      line-height: 1.61;
      letter-spacing: normal;
      @include media-breakpoint-down(xs) {
        font-size: 22px;
      }
    }

    &-section-subtitle {
      font-size: 17px;
      text-align: center;
      margin-bottom: 4rem;
    }

    &-col {
      text-align: center;
      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
      }

      p {
        @include media-breakpoint-down(sm) {
          font-size: 15px;
        }
      }

      .title {
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 500;

        @include media-breakpoint-down(sm) {
          font-size: 22px;
          margin-bottom: 8px;
        }
      }

      img.img-fluid {
        width: 193px;
      }
    }
  }

  &__featured {
    margin-top: 5rem;
    padding: 1rem 0;
    background: #F9F9F9;
    @include media-breakpoint-down(sm) {
      margin-top: 0;
      padding: 1rem 0;
    }
  }

  &__property-card {

    &-logo-wrapper {
      width: 105px;
      height: 35px;

      .logo {
        height: 100%;
        width: 100%;
        object-position: left;
        object-fit: contain;
      }
    }

    &-title {
      font-size: 22px;
      color: $hi-text-color;
      margin-bottom: 0;
      font-weight: 500;

      &:hover {
        color: $hi-text-color;
      }
    }

    &-price {
      color: $hoppler-blue;
      margin-bottom: 11px;
      font-size: 15px;
    }
    
    &-amortization {
      margin-bottom: 0;
      color: $hoppler-blue;
      font-weight: 500;
      font-size: 17px;
      font-family: $hi-body-font;
    }

    &-location, &-progress {
      font-size: 15px;
      span {
        font-weight: 500;
      }
    }

    &-header {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }

    &-highlights {
      height: 90px;
      overflow: hidden;
    }

    &-deals {
      background: #FFF6F2;
      padding: 1rem;

      .tag {
        color: $hoppler-orange;
      }

      &-lists {
        height: 90px;
        overflow: hidden;
      }
    }

    &-button {
      margin-top: 1rem;
      background: white;
      border: 1px solid black;

      &:focus {  
        outline: none;
      }
    }

    &-imgcontainer {
      overflow: hidden;
      height: 220px;

      &-img {
        height: 100%;
        width: 100%;
      }
    }
  }

  &__contact {
    margin-top: 75px;
    margin-bottom: 60px;

    @include media-breakpoint-down(sm) {
      margin-top: 48px;
      margin-bottom: 40px;
    }

    &-title {
      display: block;
      font-size: 32px;
      margin-bottom: 0;
      font-family: "Tiempos Headline", serif;
      font-weight: 500;
      // margin-bottom: 2rem;
      @include media-breakpoint-down(sm) {
        font-size: 21px;
        margin-bottom: 1rem;
      }
  
    }

    &-sub {
      font-size: 19px;
      line-height: 25px;
      margin-bottom: 2rem;
      @include media-breakpoint-down(sm) {
        font-size: 15px;
      }
    }
  }

  &__rental {
    margin-top: 5rem;

    &-header {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 2rem;
    }

    &-btn {
      margin-top: 1rem;
      padding-left: 3rem;
      padding-right: 3rem;
      background: white;
      border: 1px solid black;

      &:focus {  
        outline: none;
      }
    }
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__footer {
    background-color: #f3f3f3;
    padding: 3rem 0;

    p, a {
      font-size: 13.5px;
    }

    &-monoblack {
      @include media-breakpoint-down(md) {
        margin-top: 3rem;
      }
    }

    &-left {
      @include media-breakpoint-down(sm) {
        order: 2;
      }
    }

    &-first-sched {
      @include media-breakpoint-down(lg) {
        margin-bottom: 2rem;
      }
    }

    &-right {
      @include media-breakpoint-down(md) {
        order: 1;
      }
    }

    &-social-media-links-item {
      color: $hi-text-color;
      &:hover {
        color: $hi-text-color;
      }
    }

    &-rights {
      margin-top: 6rem;
      margin-bottom: 0;
      @include media-breakpoint-down(sm) {
        margin-top: 2rem;
      }
    }
  }

  // BROWSE DEVELOPMENTS
  &-results {

    &__pagination {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 3rem;
      @include media-breakpoint-down(sm) {
        justify-content: center;
      }

      .disable_pagination {
        opacity: 0.3;
        pointer-events: none;
      }

      .prev_paginate,
      .next_paginate {
        cursor: pointer;
        margin: 0 0.5rem;
        border-radius: 50%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        border: 1px solid black; 
        background: white;
      }

      .pagination-item {
        cursor: pointer;
        margin: 0 0.5rem;
      }

      .active_page {
        font-weight: bold;
      }
    }

    &__search-wrapper {
      height: 180px;
      border-bottom: 5px solid $hoppler-orange;
      text-align: center;
      display: flex;
      align-items: center;

      &__clear-filter {
        margin-top: -1rem;
        text-align: right;
        a {
          cursor: pointer;
          text-decoration: none;
        }
      }

      &__select {
        position: relative;
        width: 50%;

        @include media-breakpoint-down(sm) {
          padding: 0;
        }

        .icon {
          position: absolute;
          right: 1rem;
          top: calc(100%/3.5);
          z-index: -1;

          @include media-breakpoint-down(sm) {
            right: 7px;
            display: none;
          }
        }
      }

      .location {
        margin-right: 5px;
        @include media-breakpoint-down(sm) {
          margin-right: 3px
        }
      }

      .developer {
        margin-left: 5px;
        @include media-breakpoint-down(sm) {
          margin-left: 3px
        }
      }

      .input-divider {
        display: none;
        height: 5px;
        width: 10px;
        content: "";
        
        @include media-breakpoint-down(sm) {
          display: block
        }
      }

      .title {
        font-size: 25px;
        font-weight: 500;
      }

      select.form-control {
        background: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &::-ms-expand {
          display: none;
        }
      }
    }

    &__search-details {
      display: flex;
      justify-content: space-between;
      @include media-breakpoint-down(md) {
        flex-direction: column;
        justify-content: flex-start;
      }
      
      .unit-count {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
        margin-top: 2rem;
      }

      .unit-sort {
        position: relative;
        font-size: 14px;
        // padding-right: 1.2rem;
        margin-bottom: 0;
        margin-top: 2rem;

        @include media-breakpoint-down(sm) {
          margin-top: 0;
        }
        
        .activeSortBy {
          font-weight: 500;
          font-size: 14px;
        }

        .icon {
          position: absolute;
          right: 5px;
        }

        .sortby-select {
          font-weight: 500;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: transparent;
          border: 0;
        }
      }


    }
  }

}
