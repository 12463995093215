@import '../variables';
@import '../utils';
@import 'node_modules/bootstrap/scss/mixins/breakpoints';


.hp-card {
  border-top: 5px solid $hoppler-orange;
  @include media-breakpoint-down(sm) {
    border-top: none;
  }
}

.hi-hero-search-box__homelone {
  background: none;
}

.hi-hero-search-box__inputs {
    display: flex;
}

.hi-hero-search-box {
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 1px 1px 0px $hoppler-shadow-color;
  padding: 1rem;

  @include media-breakpoint-down(sm) { // SM and below
    width: 100%;
    box-shadow: none;
    padding: 18px 20px;
  }

  @include media-breakpoint-only(md) { // MD ONLY
    padding-left: 2rem;
  }

  @include media-breakpoint-up(lg) { // LG and above
    width: 460px;
    padding: 2rem;
  }

  &__categories {
    @include media-breakpoint-down(md) {
      margin-top: 1.5rem;
      margin-bottom: 0.7rem;
      position: relative;
    }

    .preselling-banner {
      position: absolute;
      right: -20px;

      border-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-left: 5px solid $hoppler-orange;

      top: -1rem;
      padding: 0.5rem 0.8rem 0 0.8rem;
      background: white;
      box-shadow: 0px 0px 7px -1px rgba(0,0,0,0.15);
      text-align: center;

      a, a:hover {
        text-decoration: none;
      }

      &__text1, &__text2 {
        display: block;
        margin-bottom: 0;
        font-family: $hi-heading-font;
        font-weight: 500;
        font-size: 13px;
      }

      &__text1 {
        color: black;
        line-height: 10px;
      }

      &__text2 {
        color: $hoppler-orange;
        text-transform: uppercase;
        // Preserve original line height from when this was done as a 'p' element
        line-height: 24px;
      }

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}

.hi-hero-search-box--amp {
  padding: 1.5rem;
}

.hi-hero-search-box__header {
  font-size: 24px;

  @include media-breakpoint-up(md) {
    font-size: 32px;
  }
  
  // font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-family: $hi-heading-font;
  line-height: 1.26;
  letter-spacing: normal;

  @include media-breakpoint-up(lg) {
    font-size: 38px;
  }
}

.hi-hero-search-box__category-button {
  height: 21px;
  font-family: Roboto;
  font-size: 15px;
  margin-right: 0.5rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-transform: uppercase;

  outline: none;
  border: none;
  color: $hi-text-color;
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;

  &.active {
    border-bottom: 2px solid $hi-text-color;
    padding-bottom: 2px;
  }
}

.preselling-label {
  &:hover {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: transparent; 
    color: $hi-text-color; 
  }
}

.hi-hero-search-box__types {
  margin: 0.5rem 0;
  position: relative;
  
  &-select {
    border: 1px solid $hi-divider-color;
    background-color: white;
    width: 100%;
    min-height: 40px;
    padding: 8px;
    box-sizing: border-box;
    font-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &-icon {
    position: absolute;
    z-index: 1;
    right: 18px;
    top: calc(100%/4.5);
    font-size: 18px;
    color: $hi-light-gray-text-color;
  }
}

.homeloan-select {
  padding-left: 0;
  padding-bottom: 0.5rem;
  
  @include media-breakpoint-up(sm) {
    background: transparent;
    padding-bottom: 0;
  }
}

.hi-hero-search-box__set-filters {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  display: block;
}