@import '../variables';

.hi-modal-container {
  z-index: 900;

  display: flex;
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(100, 100, 100, 0.6);

  @include media-breakpoint-up(lg) {
    overflow-y: scroll;
    align-items: flex-start;
    padding-top: 80px;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 96px;
  }

  @include media-breakpoint-down(sm) {
    align-items: stretch;
    justify-content: flex-start;
  }
}

.hi-modal {
  background-color: white;
  border-radius: 2px;
  min-width: 60%;

  @include media-breakpoint-down(sm) {
    width: 100vw;
    margin-top: 49px;
    overflow-y: scroll;
    // overflow-x: hidden;
  }
}

.hi-modal__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h4 {
    font-size: 22px;
    font-weight: normal;
  }
}

.hi-modal__header-close {
  border: none;
  outline: none;
  background-color: transparent;
  color: $hi-light-gray-text-color;
  font-size: 13px;

  i {
    color: $hi-light-gray-text-color;
  }
}

.hi-modal__hr {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}