@import '../variables';

  /* 
  Note:
  There is a class called hi-properties-map__info-window__property-card.
  This is styled similarly to this component but has a major difference
  where the property-card is standard across all viewport sizes while
  the info window property card's style changes drastically on mobile

  Not all sub-elements of hi-properties-map__info-window__property-card
  are redefined. Some still use the hi-property-card sub elements
  like hi-property-card__type-loc
  // */

.hi-property-card {
  color: #333;

  list-style: none;
  padding: 0;

  &:hover {
    text-decoration: none;
    color: #333;
  }

  &.active {
    // background-color: $hoppler-orange;
    box-shadow: 0 0 2px 4px #f26923;
  }

  // Wide mode changes the 
  @include media-breakpoint-up(md) {
    &--wide-mode {
      display: flex;
      flex-direction: row;

      height: 220px;

      .carousel-item,
      .carousel-inner,
      .hi-property-card__img-container {
        width: 250px;
        min-width: 250px;
        max-width: 250px;
      }

      .hi-property-card__details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .hi-property-card__bottom-group {
        flex-direction: column;
      }
    }
  }

  &__img-container {
    position: relative;
    overflow: hidden;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    li {
      margin-bottom: 1rem;
    }
    
    amp-img.cover > img {
      object-fit: cover;
    }
  }

  &__anchor {
    display: block;
  }

  &__photo, .carousel-item {
    height: 220px;
    min-height: 220px;
  }

  &__photo {
    width: 100%;
    object-fit: cover;
    display: block;

    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    // Stand-in while the image is loading
    background-color: #eee;
  }

  &__labels {
    flex: 1;

    &__small-stats {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__city {
    color: $hi-lighter-text-color;
    margin-bottom: 2rem;
  }

  &__details {
    padding: 0.5rem 0.75rem 1rem 0.75rem;

    @include media-breakpoint-up(md) {
      padding: 0.5rem 1.25rem 1rem 1.25rem;
    }
  }

  &__type-loc {
    font-size: .875rem;
    color: #999;
    text-transform: uppercase;
  }

  &__title {
    text-decoration: none;
    margin-bottom: 0.5rem;
    // Leave enough room for the title to wrap into two lines without causing
    // Unevenness in card grids
    line-height: 1.2;
    min-height: 2.8em;
  }

  &__bottom-group {
    display: flex;
    justify-content: space-between;
  }

  &__starts-at {
    margin: 0;
    padding: 0;
    color: $hoppler-blue;
    font-size: 14px;
    line-height: initial;

    margin-top: -1rem;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  &__price {
    font-weight: bold;
    color: $hoppler-blue;

    font-size: 16px;

    @include media-breakpoint-up(lg) {
      font-size: 1.2rem;
    }
  }

  &__discounted-price {
    color: $hoppler-blue;
    position: absolute;
    bottom: 65px;
    text-decoration: line-through;
    font-size: 14px
  }

  &__price-suffix {
    font-size: 14px;
    color: $hoppler-blue;
  }

  &__discounted-price-suffix {
    font-size: 12px;
    color: $hoppler-blue;
  }

  &__info {
    font-size: 15px;
    margin-right: .5rem;

    // @include media-breakpoint-only(xs) {
    //   &:last-child {
    //     width: 100%;
    //   }
    // }

    @include media-breakpoint-up(lg) {
      font-size: 14px;
      line-height: 1.5rem;
      margin-right: .75rem;
    }

    &:last-child {
      margin-right: 0;
    }

    &__label {
      display: block;

      @include media-breakpoint-up(sm) {
        display: inline;
      }
    }
  }

  &__vl-button-container {
    display: flex;
    align-items: stretch;
  }

  &__vl-button {
    font-size: .875rem;
    margin-top: 0.25rem;
    padding: 0.25rem;

    @include media-breakpoint-up(lg) {
      padding: .5rem;
    }
  }

  &__virtual-badge {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 2;
  }

  &__left-virtual-badge {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 2;
  }

  &__preselling-flag {
    position: absolute;
    padding: 4px;
    bottom: 18px;
    left: 18px;
    z-index: 2;
    border-radius: 2px;
    background-color: white;
  
    &__details {
      padding: 0.5rem 0.75rem 1rem 0.75rem;
  
      @include media-breakpoint-up(md) {
        padding: 0.5rem 1.25rem 1rem 1.25rem;
      }
  
    }
  
    &__type-loc {
      font-size: .875rem;
      color: #999;
      text-transform: uppercase;
    }
  
    &__title {
      margin-bottom: 0.5rem;
      // Leave enough room for the title to wrap into two lines without causing
      // Unevenness in card grids
      line-height: 1.2;
      min-height: 2.4em;
    }
  
    &__bottom-group {
      display: flex;
      justify-content: space-between;
    }
  
    &__starts-at {
      margin: 0;
      padding: 0;
      color: $hoppler-blue;
      font-size: 14px;
      line-height: initial;
  
      margin-top: -0.5rem;
  
      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }
  
    &__price {
      font-weight: bold;
      color: $hoppler-blue;
  
      font-size: 16px;
  
      @include media-breakpoint-up(lg) {
        font-size: 1.2rem;
      }
    }
  
    &__price-suffix {
      font-size: 14px;
      color: $hoppler-blue;
    }
  
    &__info {
      font-size: 15px;
      margin-right: .5rem;
  
      // @include media-breakpoint-only(xs) {
      //   &:last-child {
      //     width: 100%;
      //   }
      // }
  
      @include media-breakpoint-up(lg) {
        font-size: 14px;
        line-height: 1.5rem;
        margin-right: .75rem;
      }
  
      &:last-child {
        margin-right: 0;
      }
  
      &__label {
        display: block;
  
        @include media-breakpoint-up(sm) {
          display: inline;
        }
      }
    }
  
    &__vl-button-container {
      display: flex;
      align-items: stretch;
    }
  
    &__vl-button {
      font-size: .875rem;
      margin-top: 0.25rem;
      padding: 0.25rem;
  
      @include media-breakpoint-up(lg) {
        padding: .5rem;
      }
    }
  
    &__preselling-flag {
      position: absolute;
      padding: 4px;
      bottom: 18px;
      left: 18px;
      z-index: 2;
      border-radius: 2px;
      background-color: white;
  
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }
  
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
}