@import '../variables';
@import '../utils';

.hi-nguide {
  &__city-head-row {
    display: flex;
    flex-direction: column;
    margin-top: 1.25rem;
    margin-bottom: 1rem;

    h1 {
      margin-right: 1rem;
    }

    img {
      width: auto;
      object-fit: contain;
      height: 96px;

      @include media-breakpoint-up(md) {
        height: 62px;
      }

      @include media-breakpoint-up(lg) {
        height: 82px;
        width: 300px;
      }
    }

    a {
      @include media-breakpoint-down(lg) {
        background-color: transparent;
        color: $hoppler-blue;
        border: none;
        text-align: left;
        padding-left: 0;
        padding-right: 0;
      }

      @include media-breakpoint-up(md) {
        order: 100;
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: flex-end;

      // Align heading and link with the doodle
      h1, a {
        margin-bottom: .25rem;
      }
    }
  }

  &__fact-grid {
    display: flex;
    text-align: center;

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    @include hi-hide-scrollbar;

    @include media-breakpoint-up(sm) {
      justify-content: center;
      overflow-x: initial;
      -webkit-overflow-scrolling: none;
    }

    @include media-breakpoint-only(sm) {
      flex-wrap: wrap
    }

    @include media-breakpoint-up(xl) {
      flex-wrap: wrap;
    }

    &-item {
      min-width: 160px;
      margin: 0 1rem;
      padding: .5rem;

      @include media-breakpoint-only(sm) {
        width: 50%;
        margin: 0;
      }

      @include media-breakpoint-up(xl) {
        min-width: 0;
        width: 50%;
        margin: 0;
      }

      img {
        border-radius: 50%;
        width: 60%;
        border: 1px solid #eee;
      }
    }

    &-caption {
      font-weight: 500;
      text-transform: uppercase;
      display: block;
      margin-top: 1rem;
      margin-bottom: .5rem;

      font-size: 12px;
      @include media-breakpoint-up(lg) {
        font-size: 13px;
      }
    }

    &-detail {
      font-size: 12px;

      @include media-breakpoint-up(lg) {
        font-size: 13px;
      }
    }
  }

  &__village-tile {
    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }
  }

  &__attractions-header {
    color: $hoppler-orange;
    font-family: $hi-heading-font;
    font-size: 19px;

    @include media-breakpoint-up(lg) {
      font-size: 32px;
    }
  }
}
