@import '../variables';

.hi-property-finder-page {
    &__background {
        position: absolute;
        width: 100%;
        min-height: 400px;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('https://hoppler-inc.imgix.net/hoppler/pages/static/hoppler-property-finder-cover-001.jpg');
    }
    &__card {
        background-color: #FFFFFF;
        box-shadow: 0px 0px 3px 0px #70B278;
        border-radius: $border-radius;
        margin-top: 250px;
        @include media-breakpoint-down(sm) {
            margin: 0;
        }
        h1 {
            font-weight: 400;
            margin: 0 0 16px;
            font-size: 24px;
        }
        h2 {
            font-weight: 400;
            margin: 0 0 16px;
            font-size: 20px;
        }
        h3 {
            font-weight: 400;
            margin: 0 0 16px;
            font-size: 16px;
        }
        a {
            font-weight: 400;
            margin: 0 0 16px;
            font-size: 14px;
        }
    }
}
