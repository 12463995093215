@import '../variables';
@import '../utils';

.hi-event-card {

  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &.has-border {
    border-top: 1px solid $hi-divider-color;
    padding-top: 2rem;
  }

  &__image {
    object-fit: contain;

    width: 100%;

    @include media-breakpoint-up(md) {
      // height: 480px;
      width: 300px;
      min-width: 300px;
    }

    @include media-breakpoint-up(lg) {
      width: 380px;
      min-width: 380px;
    }

  }

  &__content {
    position: relative;

    margin-top: 1rem;

    @include media-breakpoint-up(md) {
      margin-top: 0;
      margin-left: 1rem;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 3rem;
    }

    &__h2 {
      font-size: 32px;
      font-weight: 500;
      font-family: $hi-heading-font;
    }

    &__date {
      font-weight: 500;
    }

    &__location {
      font-weight: 500;
    }

    &__for-questions {
      font-size: 14px;
    }

    &__share-event {
      position: relative;

      font-size: 15px;
      
      display: flex;
      flex-direction: row;
      align-items: center;

      @include media-breakpoint-up(md) {
        position: absolute;
        bottom: 4px;
      }

      .social-media-share-btn {
        font-size: 26px;
      }
    }
  }
}
