@import '../../utils';
@import '../../variables';

.hi-mag-category-bar {
  list-style: none;
  padding-left: 0;
  border-bottom: 5px solid $hoppler-orange;
  margin-bottom: 0;

  display: flex;

  li {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
      padding: .25rem;
      margin: .5rem 0;
    }

    a {
      color: #333;
    }
  }

  justify-content: center;

  // In case this needs to be sticky, add these:
  // background-color: white;
  // z-index: 8;

  li {
    font-size: 12px;
    padding: 0 .5rem;

    &:not(:last-of-type) {
      border-right: 1px solid $hi-divider-color;
    }

    @include media-breakpoint-up(xl) {
      font-size: 16px;
      padding: .25rem 1.25rem;
      margin: 1rem 0;
    }
  }
}

#hiEventModal .modal-dialog {
  @include media-breakpoint-up(sm) {
    min-width: 520px;
  }
}

.hi-mag-event-minibanner {
  background-color: $hoppler-orange;
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 69;

  text-align: center;
  padding: 4px 0;

  @include media-breakpoint-up(md) {
    display: none;
  }

  &__name {
    font-size: 14px;
  }

  &__dates, &__link {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__link {
    text-decoration: underline;
    color: white;
  }
}
