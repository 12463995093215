@import 'node_modules/bootstrap/scss/mixins/breakpoints';

.not-found-container {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(sm) {
        height: 100vh
    }

    .image-right {
        @include media-breakpoint-down(sm) {
          margin-top: 2rem
        }
    }

    .searchbox-left {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }

}