@import '../variables';
@import '../utils';

.hi-date-picker {
  position: relative;

  &__calendar-container {
    display: none;
    background-color: white;

    z-index: 99;
    position: absolute;
    top: 100%;
    left: 0;
    // transform: translateY(-24px);

    border: 1px solid $hi-filter-input-border-color;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    padding: 8px 12px;
    width: 100%;
    min-height: 160px;

    &.active {
      display: block;
    }

    &.above {

    }

    &.below {

    }

    &__control-row {
      display: flex;
      flex-direction: row;

      padding: 4px 12px;
      height: 46px;
      width: 100%;

      border-bottom: 2px solid $hoppler-orange;

      &__current-date {
        width: 75%;
        font-size: 24px;
        // font-weight: bold;

        &__year {
          margin-left: 8px;
        }

        &__year-selector {
          outline: none;
          border: none;
          background: transparent;
        }
      }

      &__controls {
        width: 25%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .btn {
          &:hover {
            background-color: $hi-divider-color;
          }
        }
      }
    }
    
  }

  &__calendar-headers {
    
  }

  &__calendar {
    width: 100%;
    
    &__date {
      width: 14.2%;
      min-height: 32px;
      float: left;
      text-align: center;

      box-sizing: border-box;
      padding: 6px 0;

      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: $hi-light-gray-text-color;
        color: white;
      }

      &.header {
        pointer-events: none;
        font-weight: bold;
      }

      &.active {
        background-color: $hoppler-blue;
        border-radius: 5px;
        color: white;
      }

      &.previous, &.disabled {
        pointer-events: none;
        opacity: 0.6;
        color: $hi-lighter-text-color;

        &:hover {
          background-color: initial;
          color: initial;
        }
      }
    }
  }

  &__input-container {

    &__input {
      width: 100%;
    }

    &__icon {
      position: absolute;
      top: calc(50% - 12px);
      right: 8px;
      z-index: 1;
      cursor: pointer;
    }
  }
}