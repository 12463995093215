@import '../variables';

.hi-results-page {

  .hi-booking__promo-sticker__collapsed {
    transition: 300ms;
    width: 105px;
    height: 81px;
    background: transparent;
    position: fixed;
    right: -15rem;
    bottom: 7rem;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  
    .close-btn {
      position: absolute;
      top: 0;
      background: $hoppler-orange;
      right: 1.1rem;
      outline: none;
      border: none;
      height: 25px;
      width: 25px;
      z-index: 25;
      border-radius: 50%;
  
      i {
        margin-left: 2px;
        margin-top: 4px;
      }
    }
  }

  .hi-booking__promo-sticker__expanded {
    transition: 300ms;
    width: 227px;
    height: 125px;
    background: transparent;
    cursor: pointer;
    position: fixed;
    right: 0;
    bottom: 7rem;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  
    .close-btn {
      position: absolute;
      top: 0;
      background: $hoppler-orange;
      left: 5px;
      outline: none;
      border: none;
      height: 25px;
      width: 25px;
      z-index: 25;
      border-radius: 50%;
      
      i {
        margin-left: 2px;
        margin-top: 4px;
      }
    }
  }

  &__booking-banner {
    width: 100%;
    display: none;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    .promo-text {
      padding:3px 0;
      background: rgb(255,130,65);
      background: linear-gradient(90deg, rgba(255,130,65,1) 0%, rgba(245,97,20,1) 100%);
      text-align: center;

      .text {
        font-size: 14px;
        font-family: $hi-heading-font;
        color: white;
      }
    }
  }

  &__building-info {
    border-top: .25rem solid $hoppler-orange;
    padding: 3rem;

    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }

    &-amenities,
    &-units,
    &-features {
      padding-left: 1rem;
      @include media-breakpoint-down(sm) {
        text-align: center;
        padding-left: 0;
      }

      .column-title {
        margin-top: 1rem;
        font-weight: bold;
        @include media-breakpoint-down(sm) {
          margin-bottom: 5px;
          margin-top: 0;
        }
      }
    }

    &-prop {
      margin-left: 1rem;
      width: 100%;
      border-collapse:separate; 
      border-spacing: 0 1rem;

      .value {
        width: 60%;
      }
    }

    &-title {
      text-align: center;
      font-weight: 500;
      font-size: 22px;
    }

    &-row {
      @include media-breakpoint-down(sm) {
        padding: 0 1rem;
      }
    }

    &-column {
      font-size: 15px;
      padding: 0.5rem 0.7rem;
      border-right: 1px solid gainsboro;

      @include media-breakpoint-down(sm) {
        border-right: 0;
        border-bottom: 1px solid gainsboro;
        padding: 1rem 0;
      }

      &:last-of-type {
        border: 0;
      }

    }
  }

  &__filters-container {
    position: sticky;
    top: $navbar-height;
    z-index: 19;

    @include media-breakpoint-up(sm) {
      top: $navbar-height-sm;
    }

    @include media-breakpoint-up(lg) {
      top: $navbar-height-lg;
    }
  }

  &__filter-bar {
    border-bottom: .25rem solid $hoppler-orange;
    background-color: white;
    padding: 16px 0;

    &.filters-open {
      @include media-breakpoint-down(sm) {
        height: calc(100vh - 60px);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      }
    }

    &__filter-wrapper {
      margin-bottom: 6rem;
    }

    &__container {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
      }
    }

    &__autocomplete-group {
      display: flex;

      .hi-location-search-box {
        flex: 1;
      }

      amp-autocomplete {
        width: 100%;
      }

      &__go {
        margin-left: 1rem;
      }
    }

    &__type-cat-row {
      display: flex;
      align-items: center;

      @include media-breakpoint-up(lg) {
        margin-right: 1rem;
      }

      @include media-breakpoint-down(md) {
        display: none;
        margin-top: 0.875rem;
      }

      &.filters-open {
        @include media-breakpoint-down(sm) {
          display: flex;
        }
      }
    }

    &__type-select {
      width: auto;
      flex: 1;
      
      @include media-breakpoint-up(md) {
        width: 150px;
        min-width: 150px;
      }
    }

    &__toggle-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1rem;

      @include media-breakpoint-up(md) {
        flex-direction: row-reverse;
        padding-top: 0;

        .btn, .hi-toggle-switch {
          margin: 0 0.5rem;
        }
      }
    }
  }

  &__set-filters-button {
    padding: 6px 0;
    font-weight: 500;
  }

  &__h1, &__sort {
    font-size: .875rem;
    line-height: 1.71;
    margin-top: .75rem;
    font-weight: 500;

    margin-bottom: 0;
    @include media-breakpoint-up(sm) {
      margin-bottom: 0.5rem;
    }
  }

  &__sort-label {
    font-weight: 400;
    display: flex;
    background-color: transparent;
    border: none;

    padding-left: 0;
    @include media-breakpoint-up(sm) {
      padding-left: initial;
    }
  }

  &__sort-choices {
    padding: .5rem;
    background-color: white;
    border: $hi-divider-color;
    border-radius: $border-radius;
    display: none;
  }

  &__results {
    background-color: #f9f9f9;
    padding: 1px 0; // Prevent child margins from going beyond our borders
    position: relative;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
    }

    &__map-desktop {
      width: 45%;

      @include media-breakpoint-up(xl) {
        width: 62%;
      }
    }

    &__map-desktop-container {
      position: sticky;
      top: calc(76px + 76px);
      height: calc(100vh - 76px);
    }

    &__map-mobile {
      width: 100%;
      background-color: white;
      display: none;

      @include media-breakpoint-up(sm) {
        // position: relative;
      }

      &.visible {
        display: block;
        position: fixed;
        min-height: calc(100% - 124px - 60px);

        @include media-breakpoint-up(sm) {
          min-height: calc(100% - 76px - 60px);
        }

        @include media-breakpoint-up(lg) {
          min-height: calc(100% - 76px - 76px);
        }
      }
    }
  }

  &__results-cards {
    list-style: none;
    padding: 0;

    .hi-property-card {
      @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
      }
    }
  }

  &__card-container {
    list-style: none;
    display: block;
  }

  &__pagination-container {
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(md) {
      justify-content: flex-end;
      margin-bottom: 1rem;
    }

    .active {
      font-weight: 700;
    }
  }

  &__seo-panel {
    padding: 2rem 0;
    border-top: .25rem solid $hoppler-orange;
    background-color: white;

    &__heading {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    &__content {
      overflow: hidden;
      max-height: 192px;

      &.opened {
        max-height: none;
      }

      h3, h4, h5, h6 {
        @include hi-type-heading-responsive-ink;
      }

      table {
        display: block;

        th:not(:first-child) {
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        tr {
          @include media-breakpoint-down(sm) {
            display: flex;
            flex-direction: column;

            &:not(:first-child) {
              padding: .5rem 0;

              td:nth-child(2)::after {
                content: ' Properties';
              }

              td:nth-child(3)::before {
                content: 'Ave. Price: ';
              }

              td {
                padding: .25rem 2rem;
              }
            }
          }
        }

        th, td {
          text-align: center;
        }

        th {
          background-color: #eee;
          padding: 1rem 2rem;
        }

        td {
          padding: .5rem 2rem;
        }

        tr {
          border-bottom: 1px solid #eee;
        }
      }
    }

    &__read-more {
      &.active {
        display: none;
      }
    }
  }

  &__suggested-p {
    margin-top: -8px;
  }

  &__no-results {
    min-height: 400px
  }

}

.hi-results-map-mobile-toggle {
  position: fixed;
  z-index: 9;

  // top: calc(124px + 60px + 12px);
  bottom: 20px;
  left: 8px;

  &__button {
    background-color: white;
    border-radius: 16px; 
  }
}

.hi-results-one-broker-notif {

  &__container {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(100, 100, 100, 0.6);

    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-top: 80px;
    padding-bottom: 4px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

  }

  &__content {
    padding: 1rem;
    background-color: white;
    border-radius: 2px;

    text-align: center;

    // added absolute to accomodate IE styling breaks
    position: absolute;
    top: 80px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      // height: 100%;
      padding: 0.75rem;
    }

    @include media-breakpoint-up(md) {
      max-width: 70%;
      left: calc(50% - 35%);
      top: 88px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 40%;
      left: calc(50% - 20%);
    }

    &__heading {
      font-family: 'Tiempos Headline';

      font-size: 24px;
      @include media-breakpoint-up(md) {
        font-size: 32px;
      }
      font-weight: 500;
      font-style: normal;
    }

    p {
      font-size: 15px;
    }
  }

  &__p {
    line-height: 1.45;
  }
}
