@import '../../mixins/type';
@import '../../utils';
@import '../../variables';

.hi-mag-article {
  &__content-container {
    position: relative;

    &.covered {
      max-height: 390px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__see-more {
      position: absolute;
      bottom: 0;
      width: calc(100% - 30px);
      height: 80%;

      background-image: linear-gradient(180deg,hsla(0,0%,100%,0),#fff);
      border-radius: 8px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;

      @include media-breakpoint-up(md) {
        width: 100%;
        height: 95%;
        left: 0;
      }

      .btn {
        width: 60%;
        height: 45px;
        
        color: $hoppler-orange;
        border: 1px solid $hoppler-orange;

        border-radius: 8px;
        outline: none;

        @include media-breakpoint-up(md) {
          font-size: 20px;
        }

        &:hover {
          background-color: $hoppler-orange;
          color: white;
        }
      }
    }

  }

  &__hero-image-container {
    margin-bottom: 1.25rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 2rem;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 2.5rem;
    }
  }

  &__hero-image {
    display: inline-block;
    object-fit: cover;
    object-position: bottom;
    max-width: 100vw;

    height: 160px;

    @include media-breakpoint-up(md) {
      height: 304px;
      max-height: 304px;
    }

    @include media-breakpoint-up(lg) {
      height: 500px;
      max-height: 500px;
    }
  }

  &__mini-crumb {
    font-size: 12px;
    padding: .5rem 0;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__title {
    @include hi-type-display-medium-desktop-ink;

    @include media-breakpoint-down(sm) {
      font-size: 21px;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
      margin-top: .75rem;
    }
  }

  &__author {
    font-size: 14px;
    line-height: 1.71;
    display: inline-block;

    margin-bottom: .75rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 1.5rem;
    }
  }

  &__author-card {
    display: flex;

    &__photo {
      width: 100px;
      height: 100px;
      min-width: 100px;
      min-height: 100px;
      object-fit: cover;

      @include media-breakpoint-up(md) {
        width: 160px;
        height: 160px;
        min-width: 160px;
        min-height: 160px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      padding-left: 1.5rem;
      @include media-breakpoint-up(md) {
        padding-left: 2.5rem;
      }
    }

    &__blurb {
      font-size: 14px;
      margin-bottom: 0;
    }

    &-name {
      margin-bottom: .75rem;
    }
  }

  &__content {
    font-size: 14px;
    line-height: 2;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 1.8;
    }

    // Prevent excessively large photos from wrecking the page
    figure, img {
      max-width: 100%;
    }

    img {
      width: 100% !important;
      height: auto !important;
      object-fit: cover;
      object-position: center;

      @include media-breakpoint-up(md) {
        object-fit: contain;
      }
    }

    ol {
      padding-left: 0 !important;
    }

    ul {
      padding-left: 16px !important;
    }

    p {
      padding-left: 0 !important;
    }
  }

  &__widget {
    padding-top: .5rem;
    margin-top: .5rem;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      @include hi-comp-card;

      border-top: 5px solid $hoppler-orange;
      padding: 0 40px;
      margin-bottom: 48px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 41px;
      margin-bottom: 80px;
    }

    &-heading {
      margin-bottom: 2rem;
    }

    &-heading, &-success-heading {
      font-family: $hi-heading-font;
      font-size: 19px;
      display: block;

      padding-bottom: .5rem;
      border-bottom: 5px solid $hoppler-orange;

      @include media-breakpoint-up(md) {
        border-bottom: none;
        font-size: 24px;
      }
    }

    &-circle-button {
      border-radius: 50%;
      margin-left: 14px;
      height: 36px;
      width: 36px;
    }

    &-success-panel {
      min-height: 496px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      a {
        font-family: $hi-heading-font;
        font-weight: 500;
        display: block;
        margin-top: 1.5rem;
      }
    }

    @include media-breakpoint-up(md) {
      .hi-property-card--wide-mode {
        min-width: 100%;
        margin-right: 1.5rem;
      }
    }
  }

  &__share {
    position: sticky;
    top: 96px;
    display: flex;

    margin-top: 1.5rem;
    @include media-breakpoint-up(md) {
      margin-top: 0;
      flex-direction: column;
      align-items: flex-end;
    }

    &-label {
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    &-list {
      display: flex;
      list-style: none;
      padding-left: 0;

      @include media-breakpoint-up(md) {
        flex-direction: column;
        align-items: flex-end;
      }

      li {
        margin-left: 1.5rem;
      }
    }
  }

  &__related {
    &-heading {
      @include media-breakpoint-down(sm) {
        text-transform: uppercase;
        font-weight: 500;
      }

      @include media-breakpoint-up(md) {
        @include hi-type-heading-desktop-ink;
      }
    }

    &-article {
      color: #333;

      &:hover {
        color: #333;
      }
    }

    &-article-name {
      margin-top: 0.5rem;
      font-weight: 500;
      color: #333;

      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }

      @include media-breakpoint-up(md) {
        font-size: 15px;
      }
    }

    &-article-info {
      font-size: 13px;
      padding-top: 0.25rem;
      color: #333;

      @include media-breakpoint-up(md) {
        font-size: 12px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 14px;
      }
    }
  }
}