@import '../../mixins/scrollbar';
@import '../../utils';
@import '../../variables';

.hi-magazine-free-resource-row {
  flex-direction: row;
  flex-wrap: unset;

  overflow-x: scroll;

  @include hi-hide-scrollbar;

}