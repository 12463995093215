
@mixin hi-type-heading {
  display: block;

  font-family: $hi-heading-font;
  font-weight: 500;
  margin-bottom: 1rem;

  font-size: 1.6rem; // Roughly 25px
  min-height: 1.75rem;

  @include media-breakpoint-up(lg) {
    font-size: 2rem;
    min-height: 2.5rem;
  }
}

@mixin hi-type-headline {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}

@mixin hi-type-headline-alt {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.28;
}

@mixin hi-type-caption {
  font-weight: 400; 
  font-size: 12px;
  line-height: 20px;
}

@mixin hi-type-title {
  font-size: 1.25rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.5rem;
  }

  font-weight: 500;
  color: #333;
  display: block;
}

@mixin hi-type-caps {
  font-weight: 500;
  font-family: $hi-body-font;
  font-size: 1rem;
  text-transform: uppercase;

  padding: 1rem 0;
  margin: 0;
  display: block;
  color: #333;
}

////////////////////////////////////////////////////////////////////////////////
// Styles from the Zeplin style guide, text style catalog
////////////////////////////////////////////////////////////////////////////////

@mixin hi-type-heading-desktop-ink {
  font-family: $hi-body-font;
  font-weight: 500;
  font-size: 22px;
  color: #333;
}

// Useful variant.
@mixin hi-type-heading-responsive-ink {
  font-size: 15px;
  font-weight: 500;

  @include media-breakpoint-up(lg) {
    @include hi-type-heading-desktop-ink;
  }
}

@mixin hi-type-display-medium-desktop-ink {
  font-family: $hi-heading-font;
  font-weight: 500;
  font-size: 32px;
  line-height: 1.25
}

@mixin hi-type-caption-desktop-subdued {
  font-size: 14px;
  color: #999;
}

@mixin hi-type-caption-mobile-ink {
  font-size: 13px;
  font-weight: normal;
  line-height: 1.92;
}
