@import '../variables';

.hi-profile-page {

  &__content {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    background-color: white;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    
    @include media-breakpoint-up(md) {
      background-color: white;
      padding: 1.5rem;
      padding-top: 2.5rem;
      border-radius: $border-radius;
      border: 1px solid $hi-divider-color;
      border-top: 5px solid $hoppler-orange;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-bottom: .5rem;
    }

    &__h1 {
      font-size: 22px;
    }

    &__controls {
      display: flex;
      flex-direction: row;
    }
  }
}

.hi-account-information {

  &__display-picture-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__display-picture {
    background-color: $hi-divider-color;
    position: relative;
    width: 260px;
    height: 260px;
    border-radius: 50%;
    overflow: hidden;

    &:hover {
      background-color: $hi-lighter-text-color;

      .hi-account-information__display-picture-cover {
        opacity: 1;
      }

      .hi-account-information__display-picture-img {
        opacity: 0.3;
      }      
    }

    &.disabled {
      &:hover {
        background-color: $hi-divider-color;

        .hi-display-picture-input {
          // cursor: auto;
        }
  
        .hi-account-information__display-picture-cover {
          opacity: 0;
          // cursor: auto;
        }
  
        .hi-account-information__display-picture-img {
          opacity: 1;
        }      
      }
    }
  }

  &__display-picture-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 8;
    cursor: pointer;

    color: white;
    opacity: 0;
    text-align: center;

    &.uploading {
      opacity: 1;
    }
  }

  &__display-picture-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: all 0.5s;
  }

  &__display-picture-input {
    position: absolute;
    width: 260px;
    height: 260px;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    z-index: 9;
  }

}

.hi-profile-page-logout {
  cursor: pointer;

  &:hover {
    text-decoration: underline !important;
  }
}