// COLORS

$hoppler-blue: #007EFE;
$hoppler-shadow-color: #888888;
$hoppler-orange: #F26923;   // New, deeper orange used in assets
$hoppler-error-color: #de3618;

$hi-text-color: #333;
$hi-lighter-text-color: #4a4a4a;
$hi-light-gray-text-color: #8b8b8b;

$hi-divider-color: #d4d1d1;

$hi-filter-input-border-color: #d4d1d1;

$hi-light-gray-bg: #f5f5f5;

// Internal values that need to be shared

// HEIGHTS
// These two must have exactly the same height. Necessary to prevent navbar
// content from moving when the mobile accordion menu opens.
$navbar-height: 60px;
$navbar-height-sm: 60px;
$navbar-height-lg: 75px;


// Bootstrap overrides
$body-color: #333;
$input-border-color: $hi-divider-color;

$theme-colors: (
  'primary': $hoppler-blue,
  'secondary': $hoppler-orange
);
$text-muted: #999999;

// Make it easier to use forms with lots of input boxes on mobile
$form-group-margin-bottom: 1.25rem;

$border-radius: 3px;
$input-btn-focus-box-shadow:  0 0 0 2px $hoppler-blue;

// FONTS!
$hi-body-font: 'Roboto', sans-serif;
$hi-heading-font: 'Tiempos Headline', serif;