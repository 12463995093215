@import '../../mixins/card';
@import '../../variables';
@import '../../utils';

@import './components/booking-image-picker';

.hi-booking-reserve {
  &__title {
    margin: 29px 0 22px 0;
    text-align: center;
    font-size: 22px;

    @include media-breakpoint-up(lg) {
      text-align: left;
      font-size: 28px;
    }
  }

  &__unit-info {
    .name {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0;
    }

    .developer {
      font-size: 14px;
    }

    .value {
      font-weight: 500;
      margin-bottom: 5px;
      font-size: 18px;
      @include media-breakpoint-down(sm) {
        margin-top: -2px;
      }
    }

    .label {
      @include media-breakpoint-down(sm) {
        width: 50%;
      }
    }

    .justify-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .custom-justify {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include media-breakpoint-down(sm) {
        align-items: flex-start;
      }
    }
  }
  
  &-content {
    // background-color: #f9f9f9;
    min-height: 100vh;

    // An easy way to make the top margins not punch through the top of this
    // element.
    display: flex;
    flex-direction: column;

    background-color: #f9f9f9;

    &__refund-text {
      font-size: 14px;
    }
  }

  &__card {
    margin-bottom: 20px;
  }

  &__main-card {

    .invalid-with-bg {
      border-color: $hoppler-error-color;
      background: #FDEFEC;
    }
    
    margin: 0 -15px;

    @include media-breakpoint-up(sm) {
      margin: unset;
    }

    @include media-breakpoint-down(xs) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &__reserve-form {
      padding: 37px 40px 67px 40px;
      @include media-breakpoint-down(sm) {
        padding: 1.25rem;
      }
    }

    &__payment-form {
      padding: 55px 40px 40px 40px;
      @include media-breakpoint-down(sm) {
        padding: 1.25rem;
      }
    }
  }

  &--disabled-panel {
    opacity: .5;
  }

  &__miniheading {
    font-weight: 500;
    display: block;
    font-size: 16px;
  }

  &__total {
    font-size: 29px;
    font-weight: 500;
  }

  &__large-type {
    font-size: 37px;
    font-weight: 500;
    @include media-breakpoint-down(xs) {
      font-size: 29px;
    }
  }

  &__dark-separator {
    border-top: 1px solid black;
    margin: 24px 0 16px 0;
  }

  &__payment-option {
    margin-right: 16px;

    &.mastercard {
      width: 38px;
      height: 29px;
    }

    &.visa {
      width: 53px;
      height: 30px;
    }

    &.gcash {
      width: 124px;
      height: 29px
    }

    &.grabpay {
      width: 97px;
      height: 26px;
    }

    &.paypal {
      width: 93px;
      height: 24px;
    }
  }

  &__payment-option-group {
    margin-bottom: 32px;
    margin-right: 16px;
  }

  &__expiry-separator {
    width: 6px;
    margin: 0 12px;
    background-color: $hi-divider-color;
    transform: rotate(15deg);
    border-radius: 8px;
  }

  &__unit-img {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }

    width: 120px;
    height: 120px;
    border-radius: $border-radius;
  }

  &__unit-price {
    &-container {
      margin-top: 10px;
      display: flex;
      align-items: center;

      @include media-breakpoint-up(md) {
        margin-top: auto;
      }
    }

    &--slashed {
      font-size: 14px;
      font-weight: 500;
      color: #d4d1d1;
      text-decoration: line-through;
      margin-left: 8px;
    }
    
    font-size: 19px;
    font-weight: 500;
    color: $hoppler-blue;
  }

  &__unit-amortization {
    color: $hoppler-blue;
    font-size: 13px;
    
    @include media-breakpoint-up(md) {
      font-size: 14px;
    }
  }

  &__unit-extra-info {
    margin: 12px 0 0 0;
    padding: 8px 0 0 0;

    display: flex;
    align-items: center;
    font-size: 14px;

    border-top: 1px solid $hi-divider-color;

    @include media-breakpoint-up(md) {
      width: 230px;

      border-top: none;
      border-left: 1px solid $hi-divider-color;
      padding: 0 16px;
    }

    ul {
      @include media-breakpoint-down(sm) {
        margin-left: 1.5em;
      }
    }

    li {
      margin: 12px 0 0 0;

      @include media-breakpoint-up(md) {
        margin: 12px 0;
      }
    }
  }

  &__total-price {
    margin-bottom: 34px;
    font-size: 32px;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  &__proceed-to-payment-section {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;

      margin: 32px 0 16px;
    }
  }
}

.hi-booking-pay {
  &__pm-badge {
    width: 171px;
    height: 40px;
  }
}

#hi-booking-payment__error-scroll-marker {
  position: relative;
  top: -60px;
}

.hi-booking-success {
  margin-top: 127px;
  text-align: center;

  @include media-breakpoint-down(sm) {
    margin-top: 4rem;
  }

  &__divider {
    margin-top: 41px;
    margin-bottom: 47px;
    @include media-breakpoint-down(sm) {
      margin-top: 39px;
      margin-bottom: 20px;
    }
  }

  &__heading {
    font-size: 32px;
    font-family: $hi-heading-font;
    color: $hoppler-orange;
    display: flex;
    justify-content: center;

    .line1 {
      margin-bottom: 0;
      @include media-breakpoint-down(sm) {
        line-height: 20px;
      }
    }

    .line2 {
      margin-left: 0.5rem;
      @include media-breakpoint-down(sm) {
        margin-left: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      display: block;
      font-size: 25px;
    }
  }

  &__subheading {
    font-family: $hi-body-font;
    @include media-breakpoint-down(sm) {
      font-size: 15px;
    }
  }

  &__contact-block {
    margin: 24px 0;
    font-size: 14px;
  }
}