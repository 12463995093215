
@mixin hi-orange-after {
  &::after {
    content: " ";
    width: 160px;
    height: 5px;
    background-color: $hoppler-orange;
    display: block;
    margin: auto;
    margin-top: 0.5rem;
  }
}
