@import '../variables';

.homeloan {
  background: white;
  font-family: $hi-body-font;


  &__banner {
    // padding: 3.5rem;

    .banner-title {
      font-size: 2.2rem;
      font-weight: bold;
      font-family: $hi-heading-font;
      color: $hi-text-color;
    }

    .banner-loan-amount {
      font-size: 2.2rem;
      font-weight: 500;
      color: $hoppler-blue;
    }

    .banner-bottom-text {
      font-size: 13.5px;
      color: $hi-light-gray-text-color;
    }
  }

  .suggested-properties {
    background: #f9f9f9;
    
    &__body {
      padding-bottom: 1rem;
      border-bottom: 3px solid $hoppler-orange;
    }
  }

    // .suggestedprop-text {
    //   border-bottom: 5px solid $hoppler-orange;
    // }
  }

