@import '../variables';
@import '../mixins/card';

.hi-transfer-cost-calculator {
  @include media-breakpoint-up(md) {
    @include hi-comp-card;
  }

  label {
    font-weight: 500;
  }

  &__heading {
    @include media-breakpoint-down(sm) {
      @include hi-type-caps;
    }

    @include media-breakpoint-up(md) {
      @include hi-type-title;
    }
  }

  &__money-row {
    display: flex;
  }

  &__money-php {
    flex: 1;
    flex-wrap: nowrap;
  }

  &__money-pct {
    min-width: 96px;
    width: 96px;
    margin-left: 1rem;
    height: 100%;
  }

  &__totals {
    margin-top: 2rem;
  }

  &__totals-group {
    margin-top: 1rem;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    &__sale-price {
      font-size: 27px;
      font-weight: 500;
      color: $hi-light-gray-text-color;
    }

    &__total-additional-fees {
      font-size: 27px;
      font-weight: 500;
      color: $hi-light-gray-text-color;
    }

    &__total-price {
      font-size: 27px;
      font-weight: 500;
      color: $hoppler-blue;
    }
  }

  &__hr {
    height: 5px;
    border-top: 5px solid $hoppler-orange;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  &__totals-group-span {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0.25rem;
  }
}