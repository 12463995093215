@import "../variables";
@import "../mixins/type";
@import "../utils";

.hi-project {
  padding-bottom: 3rem;

  @include media-breakpoint-down(sm) {
    padding-bottom: 1.5rem;
  }

  &__landing {
    @include media-breakpoint-down(sm) {
      height: 320px;
    }

    &-banner {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__details {
    margin-top: 1rem;

    &-wrapper {
      margin-top: 1rem;
      @include media-breakpoint-down(sm) {
        margin-top: 10px;
      }
    }

    &-logo {
      width: 120px;
    }

    &-btns {
      display: flex;
      flex-wrap: wrap;

      @include media-breakpoint-down(sm) {
        margin-top: 1rem;
      }

      .btn {
        height: 40px;

        @include media-breakpoint-down(sm) {
          flex: 1 0 40%;
          &:first-child {
            margin-right: 16px;
          }

          &:last-child {
            margin-top: 16px;
            width: 100%;
            margin-right: 0;
          }
        }

        @include media-breakpoint-up(md) {
          margin-right: 16px;
          min-width: 120px;
        }
      }
    }

    &-title {
      font-family: "Tiempos Headline", serif;
      font-size: 36px;
      font-weight: 500;
      font-stretch: normal;
      margin-bottom: 0.5rem;
      font-style: normal;
      line-height: 1.61;
      letter-spacing: normal;
      @include media-breakpoint-down(sm) {
        font-size: 25px;
      }
    }

    &-info {
      .label {
        font-size: 15px;
        font-weight: 500;
        margin-right: 1.5rem;
        margin-bottom: 0;

        @include media-breakpoint-down(lg) {
          margin-bottom: 0.5rem;
        }
      }

      .btn {
        display: flex;
        align-items: center;
        font-size: 15px;
        justify-content: center;
        color: white;
      }

      @include media-breakpoint-down(lg) {
        display: block;
      }
    }

    .read-more {
      font-size: 14px;
      color: $hoppler-blue;
      cursor: pointer;
    }
  }

  &__deals {
    position: sticky;
    top: 100px;

    .hp-card {
      margin-top: 2rem;
      padding: 20px 40px 30px 40px;
      border-top: 5px solid $hoppler-orange;
      @include media-breakpoint-down(sm) {
        margin-top: 1rem;
        padding: 20px 17px 31px 17px;
      }
    }

    @include media-breakpoint-down(sm) {
      position: unset;
      top: unset;
    }

    .tag {
      font-weight: 500;
      font-size: 15px;
      margin-bottom: 0;
      i.fa-tag {
        color: $hoppler-orange;
      }
    }

    .avail {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 15px;
      line-height: 23px;
    }

    &-title {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }

  &__amenities {

    &-imgs {
      position: relative;
      padding: 0 2.8rem;
      margin-top: 2rem;
      height: 200px;

      .swiper-button-next, .swiper-button-prev {
        top: calc(100%/2.5);
        &:active, &:focus {
          outline: 0;
          border: 0;
        }
      }

      .swiper-container {
        display: flex;
        justify-content: center;

        .swiper-wrapper {
          margin-bottom: 3em;
        }       
    
        .swiper-slide {
          text-align: center;
          background: #fff;
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: center;
          height: 200px;

          .card {
            width: 160px;
            height: 160px;
            overflow: hidden;

           .carousel-image {
              // position: absolute;
              cursor: pointer;
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .swiper-button-next,
      .swiper-button-prev {
        color: $hoppler-shadow-color;

        &:active, &:focus {
          outline: none;
          border: none
        }
      }
    }

    &-title {
      font-weight: 500;
    }
  }

  &__units {
    margin-top: 3rem;
    padding: 3rem 0;
    background: #F9F9F9;
    @include media-breakpoint-down(sm) {
      padding-top: 2rem;
      padding-bottom: 0.5rem;
      margin-top: 0;
    } 

    &-header {
      display: flex;
      margin-bottom: 1rem;
      justify-content: space-between;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      } 
    }

    &-filterselect {
      display: flex;
      align-items: center;
      font-size: 15px;  

      .price-text {
        margin-left: 0.3rem;
        font-weight: 500;
      }

      .dropdown-menu {
        cursor: pointer;
      }
    }

    &-filterbtn {
      font-size: 15px;
      cursor: pointer;
      font-weight: 500;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    &-title {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 0;
    }

  }

  &__similar {
    margin-top: 2.5rem;
    @include media-breakpoint-down(sm) {
     margin-top: 2rem;
    } 

    &-row {
      width: 100%;
      display: flex;
      @include media-breakpoint-down(lg) {
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      } 
    }

    &-title {
      font-weight: 500;
      font-size: 15px;
    }
  }

  &__card {
    overflow: hidden;
    margin-bottom: 2rem;

    &-carousel {
      height: 414px;
      overflow: hidden;
      display: flex;
      align-items: center;
      @include media-breakpoint-down(md) {
        height: 250px;
      } 
      
      .carousel, .carousel-inner{
        height: 100%;
      }

      .carousel-item {
        height: 100%;
        @include media-breakpoint-down(md) {
          height: unset;
        } 

        img {
          height: 100%;
          @include media-breakpoint-down(sm) {
            height: unset;
          } 
        }  
      }  

      .carousel-indicators li {
        height: 10px;
        width: 10px;
        border-radius: 50%;
      }
    }

    &-details {

      .name {
        font-weight: 500;
        font-size: 22px;
        margin-bottom: 0;
      }

      &-info {
        padding: 10px 20px;
      }

      &-price {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        
        @include media-breakpoint-down(lg) {
          display: block;
        } 
      }

      .amortization {
        color: $hoppler-blue;
        font-size: 19px;
        font-weight: 500;
        margin-right: 1rem;
        margin-bottom: 12px;
        
        @include media-breakpoint-down(lg) {
          margin-bottom: 0;
        } 
      }
      
      .minPrice {
        color: $hoppler-blue;
        font-size: 14px;
        margin-bottom: 0;
      }

      .origPrice {
        font-weight: 500;
        text-decoration: line-through;
        font-size: 15px;
        margin: 0.5rem 0;
        margin-top: -0.8rem;
        color: $hi-light-gray-text-color;

        @include media-breakpoint-down(lg) {
          margin: 0 0 0.8rem 0;
        } 
      }
    }

    &-icons {
      display: flex;
      justify-content: flex-start;

      &-group {
        display: flex;
        @include media-breakpoint-down(sm) {
          margin-top: 1rem;
        }  
      }

      @include media-breakpoint-down(lg) {
        display: block;
      }  

      &-label {
        margin-bottom: 0;
        margin-right: 2rem;
        font-size: 15px;
        
      }
    }

    &-deals {
      // margin-top: 13px;

      .deal-item {
        line-height: 20px;
      }

      &-orange {
        background: #FFF6F2;
        padding: 20px;
        border-radius: 3px;
      }

      @include media-breakpoint-down(lg) {
        margin-top: 0;
        padding: 0;
      } 
      
      .title {
        font-weight: bold;
        font-size: 15px;
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
          margin-bottom: 8px;
        } 
      }

      &-details {
        display: flex;
        justify-content: flex-start;
        
        @include media-breakpoint-down(lg) {
          display: block;
        } 
        
        .label {
          font-size: 15px;
          margin-right: 1.7rem;

          .fa-tag {
            color: $hoppler-orange;
          }
        }
      }
    }

    &-buttons {
      // margin-top: 0.5rem;
      padding: 15px 25px;
      // padding: 0 0.5rem;

      // @include media-breakpoint-down(md) {
      //   margin-top: 0;
      //   padding: 13px 25px;
      // } 

      .title {
        font-size: 14px;
        margin-bottom: 4px;
      }

      .whatsapp {
        border-color: #25d365;
        font-size: 14px;
        color: #25d365;
        transition: 300ms;
        &:hover {
          background: #25d365;
          color: white;
        }
      }
      .viber {
        border-color: #7a4f99;
        font-size: 14px;
        color: #7a4f99;
        transition: 300ms;
        &:hover {
          background: #7a4f99;
          color: white;
        }
      }
      .messenger {
        border-color: #0084ff;
        font-size: 14px;
        color: #0084ff;
        transition: 300ms;
        &:hover {
          background: #0084ff;
          color: white;
        }
      }
    }
  }

  &__image-preview {
    .close {
      position: absolute;
      right: 10px;
    }

    .carousel-inner {
      overflow: hidden;
      width: 100%;
      height: 420px;
      margin-bottom: 1rem;
      background: #eeeeee;
      @include media-breakpoint-down(sm) {
       height: 60vh;
      }
    }

    .carousel-item {
      height: 100%;
    }

    .image-preview-img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
      @include media-breakpoint-down(sm) {
        object-fit: contain;
      }
    }

    .image-preview-selector {
      height: 100px;
      max-width: 120px;
      min-width: 120px;
      overflow: hidden;
    }

    .carousel-indicators {
      overflow: auto;
      position: unset;
      margin: 1rem;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 15;
      display: flex;
      justify-content: flex-start;
      padding-left: 0;
      list-style: none;

      &::-webkit-scrollbar {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      .active {
        border: 3px solid $hoppler-blue;
      }
    }

    .image-preview-select {
      cursor: pointer;
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
  }

}