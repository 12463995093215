@import './variables';
@import './utils';

.hi-offline-property {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('https://hoppler-inc.imgix.net/hoppler/pages/static/photo-declined-001.png?auto=compress,format');
  position: relative;
  // http://dzjqf1alh39sw.cloudfront.net/hoppler/pages/static/photo-under-review-001.png
  // background-image: url('//dzjqf1alh39sw.cloudfront.net/hoppler/pages/static/photo-under-review-001.png');

  min-height: 500px;
  @include media-breakpoint-up(md) {
    min-height: 680px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;

  &__details {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 2rem;
    max-width: 80%;

    min-height: 192px;

    @include media-breakpoint-up(md) {
      width: 60%;
      max-width: none;
      padding: 1rem 4rem;

      min-height: 250px;
    }

    @include media-breakpoint-up(lg) {
      padding: 1rem 6rem;
    }

    .offline-header {
      color: $hoppler-blue;
      font-weight: 600;
      padding: 8px 0;
      font-size: 28px;
      margin-top: 0;

      @include media-breakpoint-up(md) {
        font-size: 34px;
      }
    }

    .offline-details {
      color: #333;
      padding: 8px 0;
      font-size: 18px;

      @include media-breakpoint-up(md) {
        font-size: 20px;
      }
    }
  }
}