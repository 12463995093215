@import '../variables';
@import '../mixins/type';
@import '../mixins/scrollbar';
@import '../components/preselling-banner';

.hi-home-outer-padding {
  padding: 60px 0;
  background-color: #fff;
}

.hi-home-location {
  width: 100%;

  @include media-breakpoint-up(md) {
    // &.location--makati {
    // }

    &.location--taguig, &.location--muntinlupa {
      width: 50%;
      padding-left: 0.5rem;
    }
  }
}

.hi-home-heading,
.hi-home-section-heading {
  // display: block;
  // text-align: center;

  // font-family: $hi-heading-font;
  // font-weight: 500;
  // margin-bottom: 1rem;

  // font-size: 1.6rem;
  // min-height: 1.75rem;

  // @include media-breakpoint-up(lg) {
  //   font-size: 2rem;
  //   min-height: 2.5rem;
  // }
  @include hi-type-heading;
  text-align: center;
}

.hi-home-section-heading {
  margin-bottom: 2rem;

  &::after {
    content: " ";
    width: 160px;
    height: 5px;
    background-color: $hoppler-orange;
    display: block;
    margin: auto;
    margin-top: 0.5rem;
  }
}

.hi-home-outer-container,
.hi-home-outer-container-gray {
  padding: 3rem 0;

  @include media-breakpoint-down(sm) {
    padding: 1.5rem 0;
  }

}

.hi-home-outer-container-gray {
  background-color: #f9f9f9;
}

// =============================================================================
// PRE-SELLING 
// =============================================================================

.hi-preselling {
  margin-top: 5rem;
  margin-bottom: 5rem;
  @include media-breakpoint-down(md) {
    margin-bottom: 3rem
  }

  &__header {
    display: flex;
    justify-content: center;
    font-family: $hi-heading-font;
    font-weight: 500;
    font-size: 2rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.6rem;
      text-align: center;
      display: block;
    }

    .line1 {
      margin-bottom: 0;
      @include media-breakpoint-down(sm) {
        line-height: 20px;
      }
    }

    .line2 {
      margin-left: 0.5rem;
      @include media-breakpoint-down(sm) {
        margin-left: 0;
      }
    }    
  }

  &__deals-btn {
    margin-top: 3rem;
    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
    }
  }

  &__divider {
    width: 160px;
    height: 5px;
    margin-bottom: 3rem;
    background-color: #F26923;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &__col {
    text-align: center;
    @include media-breakpoint-down(md) {
      margin-bottom: 2rem
    }

    &-image {
      width: 185px;
      height: 185px;
      margin-bottom: 2rem;
      @include media-breakpoint-down(md) {
        margin-bottom: 1rem
      }
    }

    &-title {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 18px;
      @include media-breakpoint-down(md) {
        font-size: 22px;
      }
    }

    &-text {
      @include media-breakpoint-down(md) {
        font-size: 15px;
      }
    }
  }
}

// =============================================================================
// FEATURED PROPERTIES
// =============================================================================

.hi-home-featprops-mobile__container {
  display: flex;

  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  padding: 0 0.5rem;

  @include hi-hide-scrollbar;

  &::after {
    // &nbsp in CSS content: https://stackoverflow.com/a/11637150
    content: "\00a0";
    height: 10px;
    width: 32px;
  }
}

.hi-home-featprops__entry {
  min-width: 312px;
  margin: 0.5rem;

  @include media-breakpoint-up(sm) {
    min-width: 330px;
  }
}

.hi-home-featprops__desktop-grid {
  .hi-property-card {
    @include media-breakpoint-up(md) {
      margin-bottom: 2rem;
    }
  }
}

// =============================================================================
// SELL PROPERTY
// =============================================================================

.hi-sell-property {

  &__row {
    margin-bottom: 3rem;
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
  }

  &__col {
    @include media-breakpoint-down(sm) {
      margin-bottom: 3rem;
    }
  }

  &__image {
    height: 100px;
    width: auto;
  }

  .col-title {
    font-size: 22px;
    font-weight: 500;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }
}

// =============================================================================
// TESTIMONIALS
// =============================================================================

.hi-testimonials {
  margin-top: 4rem;

  &__columns {   
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }  

  &__img {
    height: 120px;
    margin-right: 3rem;
    @include media-breakpoint-down(lg) {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }

  &__cta-btn {
    margin-bottom: 1.25rem;
    padding: 0.5rem 2rem;
  
    @include media-breakpoint-up(lg) {
      margin-bottom: 2.5rem;
    }
  }

  &__columns--mobile {
    display: none;
    overflow: hidden; 

    @include media-breakpoint-down(lg) {
      display: block;
    }

    .text-gray {
      color: $hi-light-gray-text-color;
    }

    .description {
      font-size: 14px;
    }

    .carousel-control-prev,
    .carousel-control-next {
      top: -10rem;
    }
  }

  &__col {
    padding: 0 1rem;
    display: flex;

    .description {
      font-size: 14px;
    }
  
    .details {
      font-size: 14px;
      color: $hi-light-gray-text-color;
    }
  }

}


// =============================================================================
// FEATURED CITIES
// =============================================================================

.hi-home-featcities__city {
  margin-bottom: 3rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 4rem;
  }

  &.last {
    margin-bottom: 1rem;
  }
}

.hi-home-featcities__blurb {
  margin: auto;
  text-align: center;
  max-width: 780px;

  font-weight: 300;
  font-size: 18px;
  line-height: 1.61;
}

.hi-home-featcities__city-skyline {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.25rem;
  transform: scale(0.88);
}

.hi-home-featcities__know-button {
  margin-bottom: 1.25rem;
  padding: 0.5rem 2rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 2.5rem;
  }
}

.hi-home-featcities__village__image,
.hi-home-featcities__image {
  transition: all 0.4s ease-out;

  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: $border-radius;
}

.hi-home-featcities__village__overlay,
.hi-home-featcities__overlay {
  transition: all 0.4s ease-out;
  background-color: rgba(0, 0, 0, 0.2);

  z-index: 2;
  position: absolute;
  top: 0;

  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  text-decoration: none;
}

.hi-home-featcities__village__plaque,
.hi-home-featcities__plaque {
  transition: all 0.4s ease-out;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.67);
  border: 1px solid rgba(255, 255, 255, 0.8);
  max-width: 75%;
}

.hi-home-featcities__villages-container {
  display: flex;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @include hi-hide-scrollbar;

  @include media-breakpoint-up(xl) {
    overflow: initial;
  }
}

.hi-home-featcities__village {
  width: 100%;
  min-width: 265.5px;

  @include media-breakpoint-up(xl) {
    min-width: 0;
    width: 50%;
  }

  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 1.25rem;
  }

  &:hover {
    text-decoration: none;

    .hi-home-featcities__village__overlay {
      background-color: rgba(0, 0, 0, 0.67);
    }

    .hi-home-featcities__village__plaque {
      background: transparent;
      border-color: transparent;
    }
  }

  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.hi-home-featcities__village__plaque {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hi-home-featcities__village__village-name {
  font-weight: bold;
  font-family: $hi-body-font, sans-serif;
  font-size: 16px;
  text-transform: capitalize;

  display: block;
  text-align: left;
  margin-top: 0.5rem;

  color: #333;
}

.hi-home-featcities__village__city-name {
  font-family: $hi-body-font, sans-serif;
  font-size: 12px;
  text-transform: capitalize;
  // color: white;
}

// PRE-SELLING PROJECTS

.hi-home-preselling {
  padding-top: 60px;
  font-size: 16px;
}

.hi-home-preselling__information {
  top: 120px;

  // .hi-home-section-heading {
  // }
}

.hi-home-preselling__project {
  margin-bottom: 64px;


  @include media-breakpoint-down(sm) {
    &:last-child {
      margin-bottom: 8px;
    }
  }
  
}

.hi-home-preselling__project__information {
  margin-top: 18px;
}

.hi-home-preselling__project__name {
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  display: block;
}

.hi-home-preselling__project__developer {
  font-size: 14px;
  font-weight: bold;

  margin-top: .125rem;
  margin-bottom: .25rem;
}

.hi-home-preselling__project__price {
  font-size: 14px;
  font-weight: 500;
  color: $hoppler-blue;
}

.hi-home-preselling__project__description {
  font-weight: normal;
  line-height: 1.64;
}

.hi-home-preselling__project__image {
  height: 200px;
  width: 100%;
  border-radius: $border-radius;

  // place holder bg color
  background-color: lightgray;

  object-fit: cover;
}

.hi-home-preselling__project__caption {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

// THE MAGAZINE

.hi-home-mag {
  background-color: #f9f9f9;
  padding: 3rem 0;
}

.hi-home-mag__head-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.hi-home-mag__head {
  color: $hoppler-orange;
  text-align: left;
}

.hi-home-mag__read-more {
  padding: 0.5rem 1.5rem;
  color: #333;

  @include media-breakpoint-up(lg) {
    border: 1px solid $hi-text-color;
    border-radius: 2px;
    padding: 0.5rem 1rem;

    text-decoration: none;
    text-transform: none;
    font-weight: 400;
    transition: all 0.3s;

    &:hover {
      color: white;
      background-color: $hi-lighter-text-color;
    }
  }

  @include hi-type-caps;
}

.hi-home-mag__category {
  text-transform: uppercase;
  color: $hoppler-blue;
  margin-top: 0.5rem;
  display: block;
  font-size: 0.875rem;
}

.hi-home-mag__article-title {
  font-family: 'Tiempos Headline';
  font-weight: 500;
  display: block;
  margin-bottom: 0.25rem;

  color: #333;
}

.hi-home-mag__article-row {
  @include media-breakpoint-down(sm) {
    flex-direction: row;

    flex-wrap: nowrap;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.hi-home-mag__article {
  @include media-breakpoint-down(sm) {
    min-width: 90%;
  }
}

.hi-home-work__head {
  margin-bottom: 4rem;
}

.hi-home-work__subhead {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
}

.hi-home-work__cta-panel {
  text-align: center;
  margin-bottom: 3rem;

  padding: 0 1rem;

  @include media-breakpoint-up(lg) {
    padding: 0 3rem;
  }

  @include media-breakpoint-up(xl) {
    padding: 0 4rem;
  }

  p {
    line-height: 1.8;
    min-height: 6rem;
  }
}

.hi-homepage-hero-section {
  // These images are preloaded.
  // When changing these, remember to update their corresponding entry in
  // the template preloadlinks block.
  @include media-breakpoint-up(sm) {
    background-image: url('https://hoppler-inc.imgix.net/hoppler/pages/static/hoppler-homepage-20190506.jpg?fit=crop&w=720&auto=format,compress');
    background-position: 20% 50%;
    background-size: 130%;
  }

  @include media-breakpoint-up(md) {
    background-image: url('https://hoppler-inc.imgix.net/hoppler/pages/static/hoppler-homepage-20190506.jpg?fit=crop&w=960&auto=format,compress');
    background-position: 20% 50%;
    background-size: 130%;
  }

  @include media-breakpoint-up(lg) {
    background-image: url('https://hoppler-inc.imgix.net/hoppler/pages/static/hoppler-homepage-20190506.jpg?fit=crop&w=1920&auto=format,compress');
    background-position: 20% 50%;
    background-size: 130%;

    height: 560px;
  }

  @include media-breakpoint-up(xl) {
    background-position: 0% 50%;
    background-size: 115%;
  }
}

.hi-homepage-hero-section__container {
  
  @include media-breakpoint-up(md) {
    margin: 0;
    padding: 0;
  }

  @include media-breakpoint-up(lg) {
    margin: auto;
    padding: 0 15px;
  }
}

.hi-homepage-hero-section__mobile-image {
  max-height: 200px;
  width: 100%;
  object-fit: cover;
  object-position: bottom;
}