@import '../variables';

.hi-location-search-box {
  position: relative;
}

.hi-location-search-box__input {
  border: 1px solid $hi-divider-color;
  background-color: white;
  width: 100%;
  min-height: 40px;
  padding: 8px;
  box-sizing: border-box;
  padding-left: 36px;

  font-size: 16px;

  &.invalid {
    border-color: $hoppler-error-color;
  }
}

.hi-location-search-box__icon {
  position: absolute;
  left: 10px;
  top: 7px;
  color: #888888;
  font-size: 19px;
}

.hi-location-search-box__results {
  position: absolute;
  border: 1px solid $hi-divider-color;
  border-top: none;
  border-radius: 0;
  width: 100%;
  background-color: white;
  padding: 8px 0px;
  margin-top: 2px;
  z-index: 4;
  display: none;

  &.active {
    display: block;
  }

  p {
    font-size: 16px;
    padding: 4px 16px;
    margin: 0;
    min-height: 32px;
    cursor: pointer;

    &:hover {
      background-color: $hoppler-blue;
      color: white;
    }
  }
}

.hi-location-search-box__amp-result{
  padding-bottom: 0;
  padding-top: 0;
}

.hi-location-search-box__amp-result-button {
  width: 100%;
  text-align: left;
}