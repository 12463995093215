@import '../variables';

.hi-user-agent-notification {
  
  &.visible {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding-top: 88px;

    background-color: rgba(100, 100, 100, 0.6);

  }

  &__heading {
    font-size: 24px;
    font-family: 'Tiempos Headline';
    font-weight: bold;
  }

  &__p {
    font-size: 15px;
    line-height: 1.65;
    
    &__span {
      font-size: 15px;
    }
  }

  &__a {
    font-weight: bold;
  }

  &__btn {
    margin-bottom: 0;
    float: right;
    width: 100px;
  }

  &__close {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 4px;
    right: 8px;
  }
}

.hi-user-agent-notification__content {
  display: block;
  background-color: white;
  border: 1px solid #c0c0c0;
  position: relative;
  // box-sizing: border-box;
  min-width: 280px;
  width: 100%;
  max-width: 360px;
  min-height: 194px;
  padding: 8px 12px;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    padding: 12px 16px;
    max-width: 480px;
    min-height: 164px;
  }

}