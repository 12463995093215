@import '../variables';

.hi-viewing-list {
  background-color: $hi-light-gray-bg;

  &__property-card-row {

    @include media-breakpoint-down(sm) {
      overflow-x: auto;
      flex-wrap: nowrap;

      @include hi-hide-scrollbar();
    }

    .hi-property-card {
      min-width: 300px;

      @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
      }
    }
  }

  

  &__ftf-column {
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__property-prefs-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: white;

    &.grayed {
      background-color: $hi-light-gray-bg;
    }
    
  }
}