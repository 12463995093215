
@import '../variables';

@mixin hi-top-orange {
  border-top: 5px solid $hoppler-orange; 
}

@mixin hi-border-radius-smoothen($side) {
  @if $side == 'all' {
    border-radius: $border-radius;
  }

  @if $side == 'top' {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  @if $side == 'bottom' {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
  
}