@import '../variables';
@import '../utils';

.hi-maintenance-message {
  background-color: $hoppler-orange;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;

  font-size: 14px;

  * {
    color: white
  }
}