
$standard-space: 20px;

.hi-mx-std {
  margin-left: $standard-space;
  margin-right: $standard-space;
}

.hi-my-std {
  margin-top: $standard-space;
  margin-bottom: $standard-space;
}

.hi-mt-std {
  margin-top: $standard-space;
}

.hi-mb-std {
  margin-bottom: $standard-space;
}

.hi-ml-std {
  margin-left: $standard-space;
}

.hi-mr-std {
  margin-right: $standard-space;
}

.hi-my-10px {
  margin-top: 10px;
  margin-bottom: 10px;
}

.hi-ml-10px {
  margin-left: 10px;
}

.hi-mr-10px {
  margin-right: 10px;
}
